import { IPatientenUmsaetze, IPatientenUmsaetzeQueryParams } from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createPatientenUmsaetzeApi(api: RoseApi) {
  return {
    getPatientenUmsaetze: (queryParams?: IPatientenUmsaetzeQueryParams) =>
      api.metrics.get<IPatientenUmsaetze[]>(`/api/patientenumsaetze`, {
        query: queryParams,
      }),
  };
}
