import {
  ILeistungenQueryParams,
  ILeistungenTermineKommentarParams,
  ILeistungenTermineQueryParams,
  IOffeneLeistungenResult,
  Termine,
  ILeistungenWiedervorlageInfo,
  ILeistungTerminKommentar,
  IActionResult,
  IOffeneLeistungenVerlaufResult,
  IOffeneLeistungenDetailsResult,
  IOffeneLeistungenTerminInfos,
} from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createLeistungenApi(api: RoseApi) {
  return {
    getOffeneLeistungen: (queryParams?: ILeistungenQueryParams) =>
      api.metrics.get<IOffeneLeistungenResult>(`/api/leistungen`, {
        query: queryParams,
      }),
    getOffeneLeistungenVerlauf: (queryParams?: ILeistungenQueryParams) =>
      api.metrics.get<IOffeneLeistungenVerlaufResult>(`/api/leistungen/verlauf`, {
        query: queryParams,
      }),
    getLeistungenTermine: (queryParams?: ILeistungenTermineQueryParams) =>
      api.metrics.get<IOffeneLeistungenTerminInfos>(`/api/leistungen/termine`, {
        query: queryParams,
      }),
    getOffeneLeistungenDetails: (patid: string, refDate?: string) =>
      api.metrics.get<IOffeneLeistungenDetailsResult>(`/api/leistungen/offen/${patid}`, { query: { refDate } }),
    saveTerminKommentar: (data?: ILeistungenTermineKommentarParams) =>
      api.metrics.post<IActionResult<void>>(`/api/leistungen/termine/kommentar`, data),
    saveLeistungWVLInfo: (data: ILeistungenWiedervorlageInfo) =>
      api.metrics.post(`/api/leistungen/wiedervorlage`, data),
  };
}
