/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./AGB.vue?vue&type=template&id=1b5415c0&scoped=true&lang=pug"
import script from "./AGB.vue?vue&type=script&lang=ts"
export * from "./AGB.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b5415c0",
  null
  
)

export default component.exports