
export enum LogLevel {
  TRACE = 10,
  DEBUG = 20,
  INFO = 30,
  WARN = 40,
  ERROR = 50,
  FATAL = 60,
}

export class CommonLoggerService {
  private noop = () => {};
  private logLevel = LogLevel.INFO;

  private getConsoleMethod(logName: any) {
    const c: any = console;
    if (c[logName]) {
      return c[logName].bind(c);
    }
    // fallback to console.log
    if (console.log) {
      return console.log.bind(console);
    }
    return this.noop;
  }

  get trace() {
    if (this.logLevel <= LogLevel.TRACE) {
      // console.trace prints a stacktrace
      return this.getConsoleMethod('log');
    }
    return this.noop;
  }

  get debug() {
    if (this.logLevel <= LogLevel.DEBUG) {
      // console.debug is deprecated
      return this.getConsoleMethod('log');
    }
    return this.noop;
  }

  get log() {
    if (this.logLevel <= LogLevel.INFO) {
      return this.getConsoleMethod('log');
    }
    return this.noop;
  }

  get info() {
    if (this.logLevel <= LogLevel.INFO) {
      return this.getConsoleMethod('info');
    }
    return this.noop;
  }

  get table() {
    if (this.logLevel <= LogLevel.INFO) {
      return this.getConsoleMethod('table');
    }
    return this.noop;
  }

  get warn() {
    if (this.logLevel <= LogLevel.WARN) {
      return this.getConsoleMethod('warn');
    }
    return this.noop;
  }

  get error() {
    if (this.logLevel <= LogLevel.ERROR) {
      return this.getConsoleMethod('error');
    }
    return this.noop;
  }

  get fatal() {
    if (this.logLevel <= LogLevel.ERROR) {
      return this.getConsoleMethod('error');
    }
    return this.noop;
  }
}
