export const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') >= 0;
export const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') >= 0;
export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

function iOSversion() {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    if (!v) {
      return;
    }
    // [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(String(v[3] || 0), 10)];
    return parseInt(v[1], 10);
  }
}

let _iosVersion;
try {
  _iosVersion = iOSversion() || null;
} catch (e) {
  // noop
}
export const iosVersion = _iosVersion;
