import {
  IOffeneRechnungenVerlaufResult,
  IRechnungenResult,
  IRechnungMahnstopp,
  IRechnungRequestParams,
} from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createRechnungenApi(api: RoseApi) {
  return {
    getRechnungen: (params: IRechnungRequestParams) =>
      api.metrics.get<IRechnungenResult>(`/api/rechnungen`, { query: params }),
    getOffeneRechnungenVerlauf: (params: IRechnungRequestParams) =>
      api.metrics.get<IOffeneRechnungenVerlaufResult>(`/api/rechnungen/verlauf`, { query: params }),
    saveRechnungMahninfo: (info: IRechnungMahnstopp) => api.metrics.post<void>(`/api/rechnungen/mahnung`, info),
  };
}
