import { ILabelConfig, IActionResult } from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createTerminlabelsApi(api: RoseApi) {
  return {
    saveLabelConfig: (labelConfig: ILabelConfig[]) =>
      api.metrics.post<IActionResult<void>>(`/api/terminlabels/savelabelconfig`, { labelConfig }),
    saveTerminLabels: (terminId: string, terminLabels: ILabelConfig[]) =>
      api.metrics.post<IActionResult<void>>(`/api/terminlabels/saveterminlabels`, {
        terminId,
        selectedLabels: terminLabels,
      }),
  };
}
