import * as lzs from 'lz-string';

export function parseInfoString<T>(queryInfoString: string | (string | null)[]): T {
  if (!queryInfoString) {
    throw new Error('no query infos');
  }
  const urlInfoDecompressed = lzs.decompressFromEncodedURIComponent(queryInfoString as string) || '{}';

  const info = JSON.parse(urlInfoDecompressed);
  console.log('queryInfoString parsed', urlInfoDecompressed, info);
  return info;
}
