import axios, { AxiosRequestConfig } from 'axios';
import {
  IDZRCredentials,
  IZACredentials,
  IRoseAccount,
  IProfile,
  IUIUser,
  IHealthCredentials,
  IPvsReissCredentials,
  IArcCredentials,
  ITeamfaktorCredentials,
  IFactoringOnboardingStart,
  IGutscheinValidation,
  IOnboardingDaten,
  ICareCapitalCredentials,
  IMediservCredentials,
  HEADERS,
  IZABCredentials,
  INellyCredentials,
  IFabiusCredentials,
  IActionResult,
} from '../../../types';
import {
  AuthRequestStateGetter,
  createMetricsApi,
  createR4cApi,
  createRoseApiWithAxios,
  metricsUrl,
  r4cDispatchUrl,
} from '../../../common-ui';
import { onboardingStore } from '@/state/onboardingStore';

const authRequestStateGetter: AuthRequestStateGetter = () => ({
    authToken: onboardingStore.state.roseClientInfo?.authToken,
    cid: onboardingStore.state.roseClientInfo?.cid,
  });

const { roseApi } = createRoseApiWithAxios(authRequestStateGetter);

export const metricsApi = createMetricsApi(roseApi);
export const profileApi = metricsApi.profile;
export const metricsMainApi = metricsApi.main;

export const r4cServerApi = createR4cApi(roseApi);
export const factoringApi = r4cServerApi.factoring;
export const adminApi = r4cServerApi.admin;

// **************
// NOT yet converted routes
// **************

export function sendOnboardingRequest(payload: IOnboardingDaten) {
  return postData(`${metricsUrl}/api/onboarding`, payload);
}

export function sendR4cOnboardingRequest(payload: any) {
  return postData(`${metricsUrl}/api/r4c-onboarding`, payload);
}

export function login(roseAccount: IRoseAccount): Promise<IUIUser> {
  return postData(`${metricsUrl}/auth/login`, { ...roseAccount, longLogin: true });
}

export function getProfile(cid: string, token: string): Promise<IProfile> {
  return getData(`${metricsUrl}/api/profile?withmeta=false`, {
    headers: {
      Authorization: `Bearer ${token}`,
      [HEADERS.ROSECLIENTID]: cid,
    },
  });
}

export function createPaymentIntent() {
  return getData(`${metricsUrl}/api/onboarding/createPaymentIntent`);
}

export function sendConfirmationEmail(email: string, code: string) {
  return postData(`${metricsUrl}/api/onboarding/sendConfirmationEmail`, { email, code });
}

export function loadBFSCertificate(token: string, user: string, password: string): Promise<{ zertifikat: string }> {
  return postData(`${r4cDispatchUrl}/r4cinit/downloadbfscertificate`, { token, user, password });
}

export function validateZaCredentials(creds: IZACredentials): Promise<{ success: boolean; error?: string }> {
  return postData(`${r4cDispatchUrl}/r4cinit/validatecredentials/za`, creds);
}

export function validateHealthCredentials(
  creds: Partial<IHealthCredentials>,
): Promise<{ success: boolean; error?: string }> {
  return postData(`${r4cDispatchUrl}/r4cinit/validatecredentials/health`, creds);
}

export function validatePvsReissCredentials(
  creds: Partial<IPvsReissCredentials>,
): Promise<{ success: boolean; error?: string }> {
  return postData(`${r4cDispatchUrl}/r4cinit/validatecredentials/pvsreiss`, creds);
}

export function validateArcCredentials(creds: Partial<IArcCredentials>): Promise<{ success: boolean; error?: string }> {
  return postData(`${r4cDispatchUrl}/r4cinit/validatecredentials/arc`, creds);
}

export function validateTeamfaktorCredentials(
  creds: Partial<ITeamfaktorCredentials>,
): Promise<{ success: boolean; error?: string }> {
  return postData(`${r4cDispatchUrl}/r4cinit/validatecredentials/teamfaktor`, creds);
}

export function validateDzrCredentials(creds: IDZRCredentials): Promise<{ success: boolean; error?: string }> {
  return postData(`${r4cDispatchUrl}/r4cinit/validatecredentials/dzr`, creds);
}

export function getPvsDentalRegistrationUrl(
  credentialsKey: string,
): Promise<{ success: boolean; error?: string; data?: { token: string; link: string } }> {
  return postData(`${r4cDispatchUrl}/r4cinit/getpvsdentalregistrationurl`, { credentialsKey });
}

export function validatePvsDentalCredentials(
  credentialsKey: string,
  token: string,
): Promise<{ success: boolean; error?: string; token: string }> {
  return postData(`${r4cDispatchUrl}/r4cinit/validatecredentials/pvsdental`, { credentialsKey, token });
}

export function validateCareCapitalCredentials(
  creds: Partial<ICareCapitalCredentials>,
): Promise<{ success: boolean; error?: string; token: string }> {
  return postData(`${r4cDispatchUrl}/r4cinit/validatecredentials/carecapital`, creds);
}

export function validateMediservCredentials(
  creds: Partial<IMediservCredentials>,
): Promise<{ success: boolean; error?: string; token: string }> {
  return postData(`${r4cDispatchUrl}/r4cinit/validatecredentials/mediserv`, creds);
}

export function validateZABCredentials(
  creds: Partial<IZABCredentials>,
): Promise<{ success: boolean; error?: string; token: string }> {
  return postData(`${r4cDispatchUrl}/r4cinit/validatecredentials/zab`, creds);
}

export function validateNellyCredentials(
  creds: Partial<INellyCredentials>,
): Promise<{ success: boolean; error?: string; token: string }> {
  return postData(`${r4cDispatchUrl}/r4cinit/validatecredentials/nelly`, creds);
}

export function validateFabiusCredentials(
  creds: Partial<IFabiusCredentials>,
): Promise<{ success: boolean; error?: string; token: string }> {
  return postData(`${r4cDispatchUrl}/r4cinit/validatecredentials/fabius`, creds);
}

export function sendOnboardingStarted(payload: IFactoringOnboardingStart) {
  return postData(`${r4cDispatchUrl}/r4cinit/onboardingstarted`, payload);
}

export async function validateGutschein(gutschein: string): Promise<IActionResult<IGutscheinValidation | undefined>> {
  try {
    const result = await getData(`${r4cDispatchUrl}/r4cinit/validategutschein/${gutschein}`);
    return { success: true, result };
  } catch (err) {
    console.log('validateGutschein error', err.data);
    return { success: false, result: err.data, error: err.data?.message || err.message };
  }
}

export function validateCertificatePassword(
  certificate: string,
  password: string,
  context: string,
): Promise<{ ok: boolean; message: string }> {
  return postData(`${r4cDispatchUrl}/r4cinit/checkcertificate`, { certificate, password, context });
}

async function postData(url: string, data: any = undefined) {
  try {
    const res = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res.data;
  } catch (err: any) {
    if (err.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const sc = err.response.status;
      console.error(err.message);
      if (sc >= 400 && sc <= 599 && err.response.data) {
        throw new RequestError(`error (${sc}) while POST @ ${url}`, url, sc, err.response.data, false);
      }
      throw new Error(`Service Fehler (${sc})`);
    } else if (err.request) {
      // The request was made but no response was received
      // `req` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error(err, `clienterror no response url:${url}, cfg:${JSON.stringify(err.config)}`);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error(`clienterror url:${url}, msg: ${err.message}`);
    }
  }
}

async function getData(url: string, opts?: AxiosRequestConfig) {
  try {
    const options: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
      ...opts,
    };
    const res = await axios.get(url, options);
    return res.data;
  } catch (err: any) {
    if (err.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const sc = err.response.status;
      console.error(err.message);
      if (sc >= 400 && sc <= 599 && err.response.data) {
        throw new RequestError(`error (${sc}) while GET @ ${url}`, url, sc, err.response.data, false);
      }
      throw new Error(`Service Fehler (${sc})`);
    } else if (err.request) {
      // The request was made but no response was received
      // `req` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error(err, `clienterror no response url:${url}, cfg:${JSON.stringify(err.config)}`);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error(`clienterror url:${url}, msg: ${err.message}`);
    }
  }
}

export class RequestError extends Error {
  constructor(
    message: string,
    public url: string,
    public statusCode: number,
    public data: any,
    public logged: boolean,
  ) {
    super(message);
    this.name = 'RequestError';
    this.stack = new Error().stack;
  }
}
