import { RoseApi } from '../apiTypes';
import {
  IRoseAddress,
  IRoseCustomer,
  IRoseInvoice,
  IRosePaymentMethod,
  IRoseSubscription,
  IRoseStripeInfoComplete,
  MultiClientLoginType,
  FEATURES,
} from '../../../../types';

export function createStripeApi(api: RoseApi) {
  return {
    loadStripeCustomers: () => api.metrics.get<IRoseCustomer[]>(`/api/stripe/customers`),

    loadStripeCustomer: (id: string) => api.metrics.get<IRoseCustomer>(`/api/stripe/customer/${id}`),

    loadStripeCustomerInvoices: (id: string, nocache?: boolean) =>
      api.metrics.get<IRoseInvoice[]>(`/api/stripe/invoices/customer/${id}`, { query: { nocache } }),

    loadStripeSubscriptionInvoices: (id: string, nocache?: boolean) =>
      api.metrics.get<IRoseInvoice[]>(`/api/stripe/invoices/subscription/${id}`, { query: { nocache } }),

    loadStripeInfoComplete: (id: string, nocache?: boolean) =>
      api.metrics.get<IRoseStripeInfoComplete>(`/api/stripe/info/${id}`, { query: { nocache } }),

    loadStripeSubscription: (id: string, nocache?: boolean) =>
      api.metrics.get<IRoseSubscription>(`/api/stripe/subscription/${id}`, { query: { nocache } }),

    loadStripeCustomerPaymentMethods: (stripeCustomerId: string) =>
      api.metrics.get<IRosePaymentMethod[]>(`/api/stripe/paymentmethods/${stripeCustomerId}`),

    loadStripeCustomerDefaultPaymentMethod: (stripeCustomerId: string) =>
      api.metrics.get<IRosePaymentMethod>(`/api/stripe/paymentmethod/${stripeCustomerId}`),

    changeSubscriptionTrialEnd: (id: string, trialend: string) =>
      api.metrics.post<IRoseSubscription>(`/api/stripe/subscription/${id}/changetrialend`, { trialend }),

    changeClientIban: (id: string, paymentMethod: string) =>
      api.metrics.post(`/api/stripe/changeiban/${id}`, { paymentMethod }),

    changeClientEmail: (id: string, newemail: string) =>
      api.metrics.post<void>(`/api/stripe/changeemail/${id}`, { newemail }),

    changeClientName: (id: string, name: string) => api.metrics.post<void>(`/api/stripe/changename/${id}`, { name }),

    changeClientAddress: (id: string, address: IRoseAddress) =>
      api.metrics.post<void>(`/api/stripe/changeaddress/${id}`, { address }),

    moveStripeSubscriptions: (sourceCustomerId: string, targetCustomerId: string) =>
      api.metrics.post(`/api/stripe/movesubscriptions`, { sourceCustomerId, targetCustomerId }),

    createPaymentIntent: () => api.metrics.get<{ client_secret: string }>(`/api/onboarding/createPaymentIntent`),

    deleteStripeSubscription: (
      subscriptionId: string,
      immediate: boolean,
      endOfTurnus?: boolean,
      deleteDate?: string,
    ) =>
      api.metrics.delete(`/api/stripe/subscription/${subscriptionId}`, {
        immediate,
        endOfTurnus,
        deleteDate,
      }),

    rewriteInvoice: (invoiceid: string, gutschrifttext: string, rechnungstext: string) =>
      api.metrics.post(`/api/stripe/rewriteinvoice/${invoiceid}`, { gutschrifttext, rechnungstext }),

    remailInvoice: (invoiceid: string) => api.metrics.get(`/api/stripe/remailinvoice/${invoiceid}`),

    createMetricsStripeSubscription: (
      cid: string,
      stripeCustomerId: string,
      startDate: string,
      gutscheincode: string,
      multiclientlogin: MultiClientLoginType,
      savesetupfee: boolean,
    ) =>
      api.metrics.post(`admin/metricsAboAnlegen/${cid}`, {
        stripeCustomerId,
        startDate,
        gutscheincode,
        multiclientlogin,
        savesetupfee,
      }),

    createR4CStripeSubscription: (
      cid: string,
      factoringFeature: FEATURES | null,
      trialDays?: number,
      startDate?: Date,
      additionalFeatures: FEATURES[] = [],
    ) =>
      api.r4cDispatch.post('admin/r4caboanlegen', {
        cid,
        factoringFeature,
        trialDays,
        startDate,
        additionalFeatures,
      }),

    createStripeAccount4OnboardingEntry: (id: string, reuseExistingStripeCustomer: boolean) =>
      api.metrics.post(`admin/onboarding/createstripeaccount`, { id, reuseExistingStripeCustomer }),

    createStripeAccount4CID: (cid: string, reuseExistingStripeCustomer: boolean) =>
      api.metrics.post(`/api/stripe/customer/create`, { cid, reuseExistingStripeCustomer }),
  };
}
