import { IPraxisKPIQueryParams } from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createPraxisKPIApi(api: RoseApi) {
  return {
    // TODO: Type response
    getPraxisKPI: (queryParams: IPraxisKPIQueryParams) =>
      api.metrics.get<any>(`/api/praxiskpi`, { query: queryParams }),
    getPraxisKPIExcel: (queryParams: IPraxisKPIQueryParams) =>
      api.metrics.get<{ excelAsBase64: string }>(`/api/praxiskpi/excel`, { query: queryParams }),
  };
}
