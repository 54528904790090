import { ISettings, HEADERS, HEADERVALUES } from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createSettingsApi(api: RoseApi) {
  return {
    getSettings: (noCache = false) =>
      api.metrics.get<ISettings>(`/api/settings`, {
        headers: { [HEADERS.ROSECACHECONTROL]: noCache ? HEADERVALUES.NOCACHE : '' },
      }),
    updateSettings: (params: ISettings) => api.metrics.put<ISettings>(`/api/settings`, { ...params }),
    getBenchmarkGroups: () => api.metrics.get<string[]>(`/api/benchmarkgroups`),
    patchSettings: (settingsDiff: any) => api.metrics.put<ISettings>(`/api/settings/patch`, { settingsDiff }),
  };
}
