import { VAlert } from 'vuetify/lib/components/VAlert';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.gutscheinInfo ? _c('div', {
    staticClass: "gutschein-info"
  }, [_c(VAlert, {
    attrs: {
      "type": _vm.gutscheinInfo.type
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.gutscheinInfo.msg))])])], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };