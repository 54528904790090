import { ICreateWorkflowRequest } from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createR4WorkflowsApi(api: RoseApi) {
  return {
    assignTabletWorkflow: (params: { createWorkflowRequest: ICreateWorkflowRequest; tabletToken: string }) =>
      api.r4cInstance.post(`/tablet/task/assignTabletWorkflow`, params),
    shareWorkflowPerMail: (params: { createWorkflowRequest: ICreateWorkflowRequest; mail: string }) =>
      api.r4cInstance.post(`/tablet/task/shareWorkflowPerMail`, params),
  };
}
