import { RoseApi } from '../apiTypes';
import { IExecutiveDashBoardResult, IDashboardSettings } from '../../../../types';
import * as qs from 'qs';

export function createDashboardsApi(api: RoseApi) {
  return {
    getExecutiveDashbard: (von: string, bis: string, leistungserbringer?: string) =>
      api.metrics.get<IExecutiveDashBoardResult>(
        `/api/dashboard/executive?${qs.stringify({ von, bis, leistungserbringer })}`,
      ),

    getDashboardSettings: () => api.metrics.get<IDashboardSettings>(`/api/dashboard/settings`),
    saveDashboardSettings: (params: { dashboardSettings: IDashboardSettings }) =>
      api.metrics.post<IDashboardSettings>(`/api/dashboard/settings`, params),
  };
}
