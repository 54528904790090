import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import { defineComponent } from 'vue';
import { onboardingStore } from '@/state/onboardingStore';
import { StepId } from '@/state/tasks';
export default defineComponent({
  components: {},
  data: function data() {
    return {
      fullState: onboardingStore.state,
      StepId: StepId
    };
  },
  computed: {
    shownStepNumber: {
      get: function get() {
        return onboardingStore.getters.shownStepsFlow.indexOf(onboardingStore.state.activeStep) + 1;
      },
      set: function set(stepNumber) {
        onboardingStore.commit.setActiveStep(onboardingStore.getters.shownStepsFlow[stepNumber - 1].stepId);
      }
    },
    maxStepIndexReached: function maxStepIndexReached() {
      return onboardingStore.state.maxStepIndexReached;
    },
    shownStepsFlow: function shownStepsFlow() {
      return onboardingStore.state.stepFlow;
    }
  },
  watch: {
    shownStepNumber: function shownStepNumber(newValue, oldStepNumber) {
      var _this = this;
      var refs = this.$refs.activeView;
      if (!refs) {
        return;
      }
      // CALL REFRESH HOOK ON STEP
      var activeView = refs.find(function (view) {
        return view.$attrs.stepperIndex + 1 === _this.shownStepNumber;
      });
      if (activeView && activeView.refreshHook) {
        activeView.refreshHook();
      }
      // CALL PERSIST HOOK ON STEP
      var prevView = refs.find(function (view) {
        return view.$attrs.stepperIndex + 1 === oldStepNumber;
      });
      if (prevView && prevView.persistData) {
        prevView.persistData();
      }
    }
  },
  methods: {
    viewValidUpdate: function viewValidUpdate(step, isValid) {
      console.log('viewValidupdate', isValid);
      onboardingStore.commit.setStepComplete({
        step: step,
        complete: isValid
      });
    },
    nextStep: function nextStep() {
      onboardingStore.commit.nextStep();
    },
    nextStepAccept: function nextStepAccept(step) {
      onboardingStore.commit.setStepComplete({
        step: step,
        complete: true
      });
      onboardingStore.commit.nextStep();
    },
    prevStep: function prevStep(step) {
      onboardingStore.commit.prevStep();
    },
    goto: function goto(step) {
      onboardingStore.commit.setActiveStep(step);
    }
  }
});