import {
  ITabletExtended,
  ITabletRegisterUrl,
  IRequestResult,
  StartR4cTaskRequest,
  IUploadConfig,
  IFactoringPatientKomplett,
  ReducedPatientForTabletTasks,
} from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createR4TabletApi(api: RoseApi) {
  return {
    r4cTaskCreationFailed: (errorInfo: any, rid?: string) =>
      api.r4cInstance.post<any>(`/tablet/r4cTaskCreationFailed`, { errorInfo }, { rid }),
    getTablets: (r4chost: string) => api.r4c.get<ITabletExtended[]>(r4chost, `/tablet/gettablets`),
    getTabletRegisterUrl: (r4chost: string, apiKey: string) =>
      api.r4c.get<ITabletRegisterUrl>(r4chost, `/factoringinit/tabletregisterurl/${apiKey}`),
    cancelTabletTask: (r4chost: string, tabletToken: string, info = 'uicancel') =>
      api.r4c.delete<IRequestResult<void>>(r4chost, `/tablet/deleteTask/${tabletToken}`, { info }),
    unregisterTablet: (r4chost: string, tabletToken: string) =>
      api.r4c.get<any>(r4chost, `/factoringinit/unregistertablet/${tabletToken}`),
    renameTablet: (r4chost: string, tabletToken: string, newName: string) =>
      api.r4c.post<any>(r4chost, `/factoringinit/renametablet/${tabletToken}`, { newName }),
    // QrCode
    assignQrCodeTask: (r4chost: string, tabletToken: string, terminInfos: any, patient: ReducedPatientForTabletTasks) =>
      api.r4c.post<{ taskId: string }>(r4chost, `/tablet/assignQrCodeTask`, {
        tabletToken,
        terminInfos,
        patient,
      }),
    updateQrCodeTask: (r4chost: string, tabletToken: string, terminInfos: any) =>
      api.r4c.post<{ taskId: string }>(r4chost, `/tablet/updateQrCodeTask`, {
        tabletToken,
        terminInfos,
      }),
    startR4cTask: (request: StartR4cTaskRequest) => api.r4cInstance.post(`/tablet/startR4cTask`, { request }),
    // upload task
    uploadFiles: (tabletToken: string, uploadConfig: IUploadConfig) =>
      api.r4cInstance.post(`/tablet/task/uploadFiles`, { tabletToken, uploadConfig }),
    uploadFilesFromDestopR4c: (params: { patid: string; uploadConfig: IUploadConfig }) =>
      api.r4cInstance.post(`/tablet/task/uploadFilesFromDestopR4c`, params),
    // ewe task
    shareEweTaskPerMail: (params: {
      patient: IFactoringPatientKomplett;
      clientRzId: number;
      benutzer: string;
      email: string;
      betreuungsfall: boolean;
    }) => api.r4cInstance.post(`/factoring/ewe/ewePerMail`, params),
  };
}
