import { RoseApi } from '../apiTypes';
import { IRohLeistung } from '../../../../types';

export function createPatientDebugApi(roseApi: RoseApi) {
  return {
    getPatientLeistungen: (params: { patid: string }) =>
      roseApi.metrics.get<IRohLeistung[]>(`api/patient/debug/leistungen/${params.patid}`),
    getPatids: () => roseApi.metrics.get<string[]>(`api/patient/debug/patids`),
  };
}
