"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.benchmarkConfigLeistungen = void 0;
const types_1 = require("../../../types");
const util_1 = require("../utils/util");
function benchmarkConfigLeistungen() {
    const aaCfg = {};
    aaCfg._leistungenArtGOZ = {
        name: 'GOZ',
        smartValue: 's / (b.leistungenGesamtGOZ + b.leistungenGesamtBEMA + b.leistungenArtSonstige) * 100',
        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
        virtual: true,
        value: `b.leistungenGesamtGOZ`,
        collapsed: true,
        canFilterByPatid: true,
        debug: ['leistungenGesamtGOZ'],
        childs: {
            _leistungenArtGOZBeiKasse: {
                name: 'Bei Kasse',
                help: `GOZ bei **GKV** Versicherten`,
                virtual: true,
                value: `b.leistungenGesamtGOZBeiKasse`,
                smartValue: `s / b.leistungenGesamtGOZ * 100`,
                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                childs: {},
                canFilterByPatid: true,
                debug: ['leistungenGesamtGOZBeiKasseIds'],
            },
            _leistungenArtGOZBeiPrivat: {
                name: 'Bei Privat',
                virtual: true,
                value: `b.leistungenGesamtGOZBeiPrivat`,
                help: `GOZ bei **PKV** Versicherten`,
                smartValue: `s / b.leistungenGesamtGOZ * 100`,
                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                childs: {},
                canFilterByPatid: true,
                debug: ['leistungenGesamtGOZBeiPrivat'],
            },
        },
    };
    aaCfg._leistungenArtBEMA = {
        name: 'BEMA',
        smartValue: 's / (b.leistungenGesamtGOZ + b.leistungenGesamtBEMA + b.leistungenArtSonstige) * 100',
        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
        virtual: true,
        value: `b.leistungenGesamtBEMA`,
        collapsed: true,
        childs: {},
        canFilterByPatid: true,
        debug: [`leistungenGesamtBEMAIds`],
    };
    for (const aa of util_1.abrechnungsArten.filter(x => x.typ === types_1.BemaGozType.GOZ)) {
        const aKey = `leistungenArt${aa.short}`;
        if (aaCfg._leistungenArtGOZ.childs?._leistungenArtGOZBeiKasse.childs) {
            aaCfg._leistungenArtGOZ.childs._leistungenArtGOZBeiKasse.childs[`${aKey}BeiKasse`] = {
                name: aa.artName,
                help: `${aa.name} bei **GKV** Versicherten`,
                smartValue: `s / b.leistungenGesamtGOZBeiKasse * 100`,
                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                canFilterByPatid: true,
                debug: [`${aKey}BeiKasseIds`],
            };
        }
        if (aaCfg._leistungenArtGOZ.childs?._leistungenArtGOZBeiPrivat.childs) {
            aaCfg._leistungenArtGOZ.childs._leistungenArtGOZBeiPrivat.childs[`${aKey}BeiPrivat`] = {
                name: aa.artName,
                help: `${aa.name} bei **GKV** Versicherten`,
                smartValue: `s / b.leistungenGesamtGOZBeiPrivat * 100`,
                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                canFilterByPatid: true,
                debug: [`${aKey}BeiPrivat`],
            };
        }
    }
    for (const aa of util_1.abrechnungsArten.filter(x => x.typ === types_1.BemaGozType.BEMA)) {
        if (aaCfg._leistungenArtBEMA.childs) {
            aaCfg._leistungenArtBEMA.childs[`leistungenArt${aa.short}`] = {
                name: aa.artName,
                smartValue: `s / b.leistungenGesamtBEMA * 100`,
                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                canFilterByPatid: true,
                debug: [`leistungenArt${aa.short}Ids`],
            };
        }
    }
    const gozcols = {
        leistungenZurechnungGOZA: {
            name: 'A. Allgemeine zahnärztliche Leistungen',
            help: `**GOZ** Leistungen der Gebührenziffern **0010 bis 0110**`,
        },
        leistungenZurechnungGOZB: {
            name: 'B. Prophylaktische Leistungen',
            help: `**GOZ** Leistungen der Gebührenziffern **1000 bis 1040**`,
        },
        leistungenZurechnungGOZC: {
            name: 'C. Konservierende Leistungen',
            help: `**GOZ** Leistungen der Gebührenziffern **2000 bis 2440**`,
        },
        leistungenZurechnungGOZD: {
            name: 'D. Chirurgische Leistungen',
            help: `**GOZ** Leistungen der Gebührenziffern **3000 bis 3999**`,
        },
        leistungenZurechnungGOZE: {
            name: 'E. Leistungen bei Erkrankungen der Mundschleimhaut und des Parodontiums',
            help: `**GOZ** Leistungen der Gebührenziffern **4000 bis 4150**`,
        },
        leistungenZurechnungGOZF: {
            name: 'F. Prothetische Leistungen',
            help: `**GOZ** Leistungen der Gebührenziffern **5000 bis 5340**`,
        },
        leistungenZurechnungGOZG: {
            name: 'G. Kieferorthopädische Leistungen ',
            help: `**GOZ** Leistungen der Gebührenziffern **6000 bis 6260**`,
        },
        leistungenZurechnungGOZH: {
            name: 'H. Eingliederung von Aufbißbehelfen und Schienen',
            help: `**GOZ** Leistungen der Gebührenziffern **7000 bis 7100**`,
        },
        leistungenZurechnungGOZJ: {
            name: 'J. Funktionsanalytische und funktionstherapeutische Leistungen',
            help: `**GOZ** Leistungen der Gebührenziffern **8000 bis 8100**`,
        },
        leistungenZurechnungGOZK: {
            name: 'K. Implantologische Leistungen',
            help: `**GOZ** Leistungen der Gebührenziffern **9000 bis 9090**`,
        },
        leistungenZurechnungGOZGAE: {
            name: 'Ä. Leistungen nach GOÄ',
            help: `**GOZ** Leistungen der Gebührenziffern **ä1 bis ä5300**`,
        },
        leistungenZurechnungGOZV: {
            name: 'V. Verlangensleistungen',
        },
        leistungenZurechnungGOZGU: {
            name: 'Ohne GOZ-Zurechnung',
        },
        leistungenGesamtGOZ: {
            name: 'Gesamt',
            help: `GOZ Gesamt: Summe aller GOZ Leistungen bei **PKV und GKV** Versicherten`,
        },
    };
    return {
        name: 'Leistungen',
        unit: types_1.BenchmarkConfigUnitType.EURO,
        help: 'Im ausgewählten Zeitraum erbrachte Leistungen.',
        smartValue: '100',
        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
        value: 'b.leistungenGesamt',
        isHeader: true,
        virtual: true,
        childs: {
            _zurechnung: {
                name: 'Nach Zurechnung',
                isHeader: true,
                value: 'b.leistungenGesamt',
                unit: types_1.BenchmarkConfigUnitType.EURO,
                virtual: true,
                help: artHelpText,
                childs: {
                    _goz: {
                        name: 'GOZ',
                        isHeader: true,
                        value: 'b.leistungenGesamtGOZ',
                        unit: types_1.BenchmarkConfigUnitType.EURO,
                        virtual: true,
                        help: gozHelpText,
                        childs: {
                            ...(0, util_1.mapObject)(gozcols, v => `_${v}`, (p, k) => ({
                                name: p.name,
                                help: p.help,
                                value: `b.${k}BeiKasse + b.${k}BeiPrivat`,
                                virtual: true,
                                smartValue: `s / (b.leistungenGesamtGOZ) * 100`,
                                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                                collapsed: true,
                                childs: {
                                    [`${k}BeiKasse`]: {
                                        name: 'Bei Kasse',
                                        help: `${p.help} bei **GKV** Versicherten`,
                                        smartValue: `s / (b.${k}BeiKasse + b.${k}BeiPrivat) * 100`,
                                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                                    },
                                    [`${k}BeiPrivat`]: {
                                        name: 'Bei Privat',
                                        help: `${p.help} bei **PKV** Versicherten`,
                                        smartValue: `s / (b.${k}BeiKasse + b.${k}BeiPrivat) * 100`,
                                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                                    },
                                },
                            })),
                        },
                    },
                    _bema: {
                        name: 'BEMA',
                        isHeader: true,
                        value: 'b.leistungenGesamtBEMA',
                        unit: types_1.BenchmarkConfigUnitType.EURO,
                        virtual: true,
                        help: bemaHelpText,
                        childs: {
                            leistungenZurechnungBEMA1: {
                                name: 'Teil 1 - Konservierende und chirurgische Leistungen und Röntgenleistungen',
                                smartValue: 's / b.leistungenGesamtBEMA * 100',
                                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                            },
                            leistungenZurechnungBEMA2: {
                                name: 'Teil 2 - Kieferbruch, Kiefergelenkserkrankungen',
                                smartValue: 's / b.leistungenGesamtBEMA * 100',
                                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                            },
                            leistungenZurechnungBEMA3: {
                                name: 'Teil 3 - Kieferorthopädische Behandlung',
                                smartValue: 's / b.leistungenGesamtBEMA * 100',
                                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                            },
                            leistungenZurechnungBEMA4: {
                                name: 'Teil 4 - Systematische Behandlung von Parodontopathien',
                                smartValue: 's / b.leistungenGesamtBEMA * 100',
                                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                            },
                            leistungenZurechnungBEMA5: {
                                name: 'Teil 5 - Versorgung mit Zahnersatz und Zahnkronen',
                                smartValue: 's / b.leistungenGesamtBEMA * 100',
                                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                            },
                            // leistungenBZurechnungEMABAE: {
                            //   name: 'Ä. Leistungen nach GOÄ',
                            //   smartValue: 's / b.leistungenGesamtBEMA * 100',
                            //   smartUnit: BenchmarkConfigUnitType.PERCENT,
                            // },
                            leistungenZurechnungBEMABP: {
                                name: 'Pauschalen',
                                smartValue: 's / b.leistungenGesamtBEMA * 100',
                                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                            },
                            leistungenZurechnungBEMABUKBG: {
                                name: 'Unfallkasse und Berufsgenossenschaft',
                                smartValue: 's / b.leistungenGesamtBEMA * 100',
                                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                            },
                            leistungenZurechnungBEMABU: {
                                name: 'Ohne BEMA-Zurechnung',
                                smartValue: 's / b.leistungenGesamtBEMA * 100',
                                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                                help: `Leistungen, die keinem Teil 1-5 der BEMA oder GOÄ zugerechnet werden können, werden als "ohne Zurechnung" subsummiert.`,
                                debug: ['leistungenBEMABUIds'],
                            },
                            leistungenGesamtBEMA: {
                                name: 'Gesamt',
                                smartValue: 's / b.leistungenGesamt * 100',
                                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                            },
                        },
                    },
                    _ebm: {
                        name: 'EBM',
                        value: 'b.leistungenZurechnungEBM',
                        unit: types_1.BenchmarkConfigUnitType.EURO,
                        smartValue: 's / b.leistungenGesamt * 100',
                        virtual: true,
                        help: 'EBM-Leistungen, Einheitliche Bewertungsmaßstab',
                    },
                    _loz: {
                        name: 'Ohne BEMA/GOZ/EBM-Zurechnung',
                        value: 'b.leistungenZurechnungOhne',
                        unit: types_1.BenchmarkConfigUnitType.EURO,
                        virtual: true,
                        smartValue: 's / b.leistungenGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                    },
                    leistungenGesamt: {
                        name: 'Gesamt (BEMA + GOZ + EBM + Ohne Zurechnung)',
                        smartValue: '100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                    },
                },
            },
            _abrechnungsart: {
                name: 'Nach Abrechnungsart',
                isHeader: true,
                value: 'b.leistungenGesamt',
                unit: types_1.BenchmarkConfigUnitType.EURO,
                virtual: true,
                help: artHelpText,
                childs: {
                    ...aaCfg,
                    _leistungenGesamt: {
                        name: 'Gesamt',
                        virtual: true,
                        value: 'b.leistungenGesamt',
                        childs: {
                            leistungenGesamtGOZBeiPrivat: {
                                name: 'GOZ bei Privat',
                                smartValue: `s / (b.leistungenGesamtGOZ) * 100`,
                                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                            },
                            leistungenGesamtGOZBeiKasse: {
                                name: 'GOZ bei Kasse',
                                smartValue: `s / (b.leistungenGesamtGOZ) * 100`,
                                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                            },
                            leistungenGesamtGOZ: {
                                name: 'GOZ Gesamt',
                                smartValue: `s / (b.leistungenGesamt) * 100`,
                                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                            },
                            leistungenGesamtBEMA: {
                                name: 'BEMA Gesamt',
                                smartValue: `s / (b.leistungenGesamt) * 100`,
                                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                            },
                            leistungenArtSonstige: {
                                name: 'Sonstige',
                                smartValue: 's / (b.leistungenGesamt) * 100',
                                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                            },
                            leistungenArtOhne: {
                                name: 'Ohne Art',
                                smartValue: 's / (b.leistungenGesamt) * 100',
                                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                            },
                        },
                    },
                },
            },
        },
    };
}
exports.benchmarkConfigLeistungen = benchmarkConfigLeistungen;
const gozHelpText = `
GOZ Leistungen nach Gebührenziffern gruppiert:

- **Teil A (0010 ff.)** Allgemeine zahnärztliche Leistungen
- **Teil B (1000 ff.)** Prophylaktische Leistungen
- **Teil C (2000 ff.)** Konservierende Leistungen
- **Teil D (3000 ff.)** Chirurgische Leistungen
- **Teil E (4000 ff.)** Leistungen bei Erkrankungen der Mundschleimhaut und des Parodontiums
- **Teil F (5000 ff.)** Prothetische Leistungen
- **Teil G (6000 ff.)** Kieferorthopädische Leistungen
- **Teil H (7000 ff.)** Eingliederung von Aufbissbehelfen und Schienen
- **Teil J (8000 ff.)** Funktionsanalytische und funktionstherapeutische Leistungen
- **Teil K (9000 ff.)** Implantologische Leistungen
- **Teil L (0500 ff.)** Zuschläge zu bestimmten zahnärztlich-chirurgischen Leistungen

Die Gruppierung nach Gebührenziffern beinhaltet einige unerwartete Einordnungen, z.B. sind Positionen der Einzelzahnkronen (2200 ff.) in der Ordnung der GOZ den konservierenden Leistungen zugeordnet.

`;
const bemaHelpText = `
BEMA Leistungen nach BEMA-Teilbereichen gruppiert:

- **Teil 1** - Konservierende und chirurgische Leistungen und Röntgenleistungen
- **Teil 2** - Kieferbruch, Kiefergelenkserkrankungen
- **Teil 3** - Kieferorthopädische Behandlung
- **Teil 4** - Systematische Behandlung von Parodontopathien
- **Teil 5** - Versorgung mit Zahnersatz und Zahnkronen
`;
const artHelpText = `
Zahnärztliche Leistungen nach Abrechnungsart gruppiert, GOZ unterteilt in bei Privat und bei Kasse. Bei der Gruppierung nach Abrechnungsart gehören Leistungen wie z.B. Einzelzahnkronen (2200 ff.) der Abrechnungsart Zahnersatz (ZE) an.
`;
