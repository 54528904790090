import { IFreshDeskCompany, IFreshDeskContact, IFreshDeskTicket, IRoseTicket } from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createHelpdeskApi(api: RoseApi) {
  return {
    getCompany: (id: number) => api.metrics.get<IFreshDeskCompany>(`/api/helpdesk/companies/${id}`),
    getCompanies: () => api.metrics.get<IFreshDeskCompany[]>(`/api/helpdesk/companies`),
    getContacts: (companyId: number) => api.metrics.get<IFreshDeskContact[]>(`/api/helpdesk/contacts/${companyId}`),
    getTickets: (companyId: number) => api.metrics.get<IFreshDeskTicket[]>(`/api/helpdesk/tickets/${companyId}`),
    createTicket: (ticket: IRoseTicket) => api.metrics.post<number>(`/api/helpdesk/tickets`, ticket),
    updateContact: (contact: Partial<IFreshDeskContact>) => api.metrics.put<void>(`/api/helpdesk/contact`, contact),
    updateCompany: (company: Partial<IFreshDeskCompany>) => api.metrics.put<void>(`/api/helpdesk/company`, company),
  };
}
