// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat

export function numeral(
  value: number | string,
  options?: {
    style?: 'decimal' | 'currency' | 'percent' | 'unit';
    currency?: 'EUR';
    fractionDigits?: number;
  },
) {
  return new Intl.NumberFormat('de-DE', {
    style: options?.style,
    currency: options?.currency,
    minimumFractionDigits: options?.fractionDigits ?? 2,
    maximumFractionDigits: options?.fractionDigits ?? 2,
  }).format(Number(value));
}
