import { RoseApi } from '../apiTypes';
import { IContact } from '../../../../types';

export function createContactApi(api: RoseApi) {
  return {
    getContacts: () => api.metrics.get<IContact[]>(`/api/contact`),

    createContact: (contact: IContact) => api.metrics.put<void>(`/api/contact`, { contact }),

    updateContact: (contactEmail: string, contact: IContact) =>
      api.metrics.post<void>(`/api/contact`, { contact, contactEmail }),

    deleteContact: (contactMail: string) => api.metrics.delete<void>(`/api/contact`, { contactMail }),
  };
}
