import _createForOfIteratorHelper from "/builds/rose-metrics/rose-metrics/onboarding/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _defineProperty from "/builds/rose-metrics/rose-metrics/onboarding/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.flat-map.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.unscopables.flat-map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.flat-map.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { filter, isNil, chain, map, isEmpty, intersection } from 'lodash';
import { defineComponent } from 'vue';
import { hasApp as _hasApp, hasAppFeature as _hasAppFeature, onboardingStore } from '@/state/onboardingStore';
import { paketeR4c, featureMatrix, paketeR4cMap, findAppChoice, isFeatureAlreadySelectedElseWhere as _isFeatureAlreadySelectedElseWhere, findAppChoiceByFeature } from '@/state/pakete';
import { isProd, isStaging, isDev } from '../../../common-ui';
import { FEATURES, R4CAPP } from '../../../types';
import { StepId } from '@/state/tasks';
export default defineComponent({
  components: {},
  props: ['viewParams'],
  data: function data() {
    return {
      selectedFeatures: _defineProperty(_defineProperty(_defineProperty({}, R4CAPP.FACTORING, FEATURES.NONE), R4CAPP.DOCUMENTS, FEATURES.NONE), R4CAPP.ANAMNESE, FEATURES.NONE),
      onboardingStore: onboardingStore,
      isDev: isDev,
      isStaging: isStaging,
      isProd: isProd,
      FEATURES: FEATURES,
      R4CAPP: R4CAPP,
      currentTabIdx: 0,
      lastTab: R4CAPP.DOCUMENTS,
      proceedToRechenzentren: false
    };
  },
  computed: {
    paketeR4cToShow: function paketeR4cToShow() {
      return filter(paketeR4c, function (app) {
        return !isProd || app.live;
      });
    },
    tabs: function tabs() {
      var tabs = [];
      if (!this.editR4cApps) {
        tabs.push('highlights');
      }
      var _iterator = _createForOfIteratorHelper(this.paketeR4cToShow),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var app = _step.value;
          tabs.push(app.id);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return tabs;
    },
    currentTab: function currentTab() {
      return this.tabs[this.currentTabIdx];
    },
    selectedApp: function selectedApp() {
      return paketeR4cMap[this.currentTab];
    },
    selectedFeatureMatrix: function selectedFeatureMatrix() {
      if (this.currentTab !== 'highlights') {
        return featureMatrix(paketeR4cMap[this.currentTab]);
      }
      return undefined;
    },
    hasFactoringAndAnamnese: function hasFactoringAndAnamnese() {
      return this.selectedFeatures[R4CAPP.ANAMNESE] === FEATURES.ANAMNESE_DIAMOND && this.selectedFeatures[R4CAPP.FACTORING] === FEATURES.FACTORINGEWE;
    },
    priceFactoringAndAnamnese: function priceFactoringAndAnamnese() {
      var factoringEweChoice = findAppChoice(R4CAPP.FACTORING, FEATURES.FACTORINGEWE) || {
        price: 0
      };
      var anamneseChoice = findAppChoice(R4CAPP.ANAMNESE, FEATURES.ANAMNESE_DIAMOND) || {
        price: 0
      };
      return (factoringEweChoice === null || factoringEweChoice === void 0 ? void 0 : factoringEweChoice.price) + (anamneseChoice === null || anamneseChoice === void 0 ? void 0 : anamneseChoice.price);
    },
    priceAnamneseDiamond: function priceAnamneseDiamond() {
      var anamneseChoice = findAppChoice(R4CAPP.ANAMNESE, FEATURES.ANAMNESE_DIAMOND) || {
        price: 0
      };
      return anamneseChoice === null || anamneseChoice === void 0 ? void 0 : anamneseChoice.price;
    },
    priceFactoring: function priceFactoring() {
      var factoringChoice = findAppChoice(R4CAPP.FACTORING, FEATURES.FACTORINGEWE) || {
        price: 0
      };
      return factoringChoice === null || factoringChoice === void 0 ? void 0 : factoringChoice.price;
    },
    priceDocuments: function priceDocuments() {
      var docsChoice = findAppChoice(R4CAPP.DOCUMENTS, FEATURES.DOCUMENTS_FLAT) || {
        price: 0
      };
      return docsChoice === null || docsChoice === void 0 ? void 0 : docsChoice.price;
    },
    editR4cApps: function editR4cApps() {
      var _a;
      return !!((_a = this.viewParams) === null || _a === void 0 ? void 0 : _a.editR4cApps);
    },
    somethingMeaningfulWasSelected: function somethingMeaningfulWasSelected() {
      var res = !chain(this.selectedFeatures).values().filter(function (f) {
        return f !== FEATURES.NONE;
      }).isEmpty().value() || this.editR4cApps;
      console.log('somethingMeaningfulWasSelected', this.selectedFeatures, res);
      return res;
    }
  },
  mounted: function mounted() {
    console.log('query', this.$route.query.d);
    console.log('viewParams', this.viewParams);
    if (this.editR4cApps) {
      // set current packages
      if (onboardingStore.getters.factoringActive) {
        this.selectedFeatures[R4CAPP.FACTORING] = onboardingStore.getters.factoringBasicActive ? FEATURES.FACTORING : FEATURES.FACTORINGEWE;
        // if we already have rz, then do not enable rz step
        onboardingStore.commit.setSkipStepByStepId({
          stepId: StepId.r4cRechenzentren,
          skip: true
        });
      }
      if (onboardingStore.getters.documentsActive) {
        if (onboardingStore.getters.documentsSmallActive) {
          this.selectedFeatures[R4CAPP.DOCUMENTS] = FEATURES.DOCUMENTS_SMALL;
        }
        if (onboardingStore.getters.documentsBigActive) {
          this.selectedFeatures[R4CAPP.DOCUMENTS] = FEATURES.DOCUMENTS_BIG;
        }
        if (onboardingStore.getters.documentsFlatActive) {
          this.selectedFeatures[R4CAPP.DOCUMENTS] = FEATURES.DOCUMENTS_FLAT;
        }
      }
    }
    this.setSkipStep();
    // this view cannot be invalid
    this.$emit('viewValidUpdate', true);
  },
  methods: {
    selectFeature: function selectFeature(appId, feature) {
      var reset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      this.proceedToRechenzentren = false;
      if (reset) {
        for (var _appId in this.selectedFeatures) {
          if (Object.prototype.hasOwnProperty.call(this.selectedFeatures, _appId)) {
            this.selectedFeatures[_appId] = FEATURES.NONE;
          }
        }
      }
      this.selectedFeatures[appId] = feature;
      this.setSkipStep();
    },
    selectFactoringAndAnamnese: function selectFactoringAndAnamnese() {
      this.selectedFeatures[R4CAPP.FACTORING] = FEATURES.FACTORINGEWE;
      this.selectedFeatures[R4CAPP.ANAMNESE] = FEATURES.ANAMNESE_DIAMOND;
      this.proceedToRechenzentren = true;
      console.log('selectFactoringAndAnamnese', this.selectedFeatures, this.proceedToRechenzentren);
      this.setSkipStep();
    },
    hasApp: function hasApp(app) {
      return _hasApp(app);
    },
    hasAppFeature: function hasAppFeature(app, feature) {
      return _hasAppFeature(app, feature);
    },
    hasSelectedFeatures: function hasSelectedFeatures(appId) {
      var _this = this;
      var _a;
      // check if other include features from this app
      var thisAppsFeatures = map((_a = paketeR4cMap[appId]) === null || _a === void 0 ? void 0 : _a.choices, function (c) {
        return c.feature;
      });
      var otherApps = chain(paketeR4cMap).keys().filter(function (k) {
        return k !== appId;
      }).value();
      var otherAppSelectedFeatures = chain(otherApps).map(function (oa) {
        return _this.selectedFeatures[oa];
      }).compact().filter(function (f) {
        return f !== FEATURES.NONE;
      }).value();
      var otherAppCheck = chain(otherAppSelectedFeatures).flatMap(function (f) {
        return findAppChoiceByFeature(f);
      }).compact().flatMap(function (c) {
        return c.includesOtherFeatures;
      }).compact().value();
      // console.log(
      //   'otherAppSelectedFeatures',
      //   appId,
      //   thisAppsFeatures,
      //   otherApps,
      //   otherAppSelectedFeatures,
      //   otherAppCheck,
      // );
      if (!isEmpty(intersection(thisAppsFeatures, otherAppCheck))) {
        return true;
      }
      return this.selectedFeatures[appId] !== FEATURES.NONE;
    },
    isFeatureSelected: function isFeatureSelected(appId, feature) {
      return this.selectedFeatures[appId] === feature || this.isFeatureAlreadySelectedElseWhere(appId, feature);
    },
    isFeatureAlreadySelectedElseWhere: function isFeatureAlreadySelectedElseWhere(appId, feature) {
      return _isFeatureAlreadySelectedElseWhere(appId, feature, this.selectedFeatures).included;
    },
    setSkipStep: function setSkipStep() {
      var skip = this.selectedFeatures[R4CAPP.FACTORING] === FEATURES.NONE || isNil(this.selectedFeatures[R4CAPP.FACTORING]);
      if (!onboardingStore.getters.factoringActive) {
        onboardingStore.commit.setSkipStepByStepId({
          stepId: StepId.r4cRechenzentren,
          skip: skip
        });
      }
    },
    persistData: function persistData() {
      onboardingStore.commit.setFactoringFeature(this.selectedFeatures[R4CAPP.FACTORING]);
      onboardingStore.commit.setDocumentFeature(this.selectedFeatures[R4CAPP.DOCUMENTS]);
      onboardingStore.commit.setAnamneseFeature(this.selectedFeatures[R4CAPP.ANAMNESE]);
    },
    goto: function goto(id) {
      var e = document === null || document === void 0 ? void 0 : document.getElementById(id);
      console.log('goto', document, e);
      e === null || e === void 0 ? void 0 : e.scrollIntoView();
    },
    nextTab: function nextTab() {
      this.currentTabIdx++;
    },
    next: function next() {
      this.$emit('next');
      // since this was the first interaction add unload handler here
      if (isProd || isStaging) {
        window.onbeforeunload = function () {
          return 'Sind sie sicher, dass Sie den Vorgang abbrechen wollen?';
        };
      }
    }
  }
});