import {
  ICharlyFileInfo,
  IDocumentFileWithSignatures,
  IDocumentPredefinedSignature,
  IDocumentSignSignaturePosition,
  IDocumentTaskSendParams,
  IFactoringPatientKomplett,
  IRequestResult,
  ITabletTaskInfoDocument,
  IDocumentPreviewMeta,
  ITabletTaskWrapped,
} from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createDocumentsApi(api: RoseApi) {
  return {
    sendDocumentsPerMail: ({
      r4chost,
      token,
      patMail,
      files,
      taskId,
    }: {
      r4chost: string;
      token: string;
      patMail: string;
      taskId: string;
      files: IDocumentFileWithSignatures[];
    }) =>
      api.r4c.post<void>(r4chost, `/tablet/sendDocumentsPerMail`, {
        patMail,
        files,
        tabletToken: token,
        taskId,
      }),

    transferTaskToTabletForSignature: ({
      r4chost,
      token,
      files,
      taskId,
    }: {
      r4chost: string;
      token: string;
      taskId: string;
      files: IDocumentFileWithSignatures[];
    }) =>
      api.r4c.post<void>(r4chost, `/tablet/transferTaskToTabletForSignature`, {
        files,
        tabletToken: token,
        taskId,
      }),

    createSignatureRectPreviews: (r4chost: string, token: string, files: IDocumentFileWithSignatures[]) =>
      api.r4c.post<void>(r4chost, `/tablet/createSignatureRectPreviews`, {
        files,
        tabletToken: token,
      }),

    assignTabletDocumentTask: (
      r4chost: string,
      cid: string,
      tabletId: string,
      patient: IFactoringPatientKomplett,
      fileInfo?: ICharlyFileInfo,
      start?: Date,
    ) =>
      api.r4c.post<IRequestResult<void>>(r4chost, `/tablet/task/document`, { cid, tabletId, patient, fileInfo, start }),

    createDocumentTaskFromDesktopR4c: (patient: IFactoringPatientKomplett) =>
      api.r4cInstance.post<{ task: ITabletTaskWrapped<ITabletTaskInfoDocument> }>(
        `/tablet/task/document/fromDesktopR4C`,
        {
          patient,
        },
      ),

    createFilePreview: (params: { taskId: string; ablage: string; file: string; pageNum?: number }) =>
      api.r4cInstance.post<{
        renderedUrl: string;
        signPdfUrl: string;
        pageCount: number;
        pdfFileName: string;
        previewMeta?: IDocumentPreviewMeta;
      }>(`/tablet/createFilePreview`, params),

    updateFileListing: ({ taskId }: { taskId: string }) =>
      api.r4cInstance.post<{
        fileInfo: ICharlyFileInfo;
        suggestedSignatureRects: {
          fileName: string;
          suggestion: IDocumentSignSignaturePosition;
        }[];
      }>(`/tablet/task/fileListing/`, {
        taskId,
      }),

    acceptSignedDocument: (params: { token: string; taskId: string; response: IDocumentTaskSendParams }) =>
      api.r4cInstance.post<void>(`/tablet/task/acceptSignedDocument`, params),

    acceptSignedDocumentShared: (params: { shareEntryId: string; response: IDocumentTaskSendParams }) =>
      api.r4cInstance.post<void>(`/documents/task/acceptSignedDocumentShared`, params),

    acceptSignedDocumentTakeover: (params: {
      takeoverSecret: string;
      taskId: string;
      response: IDocumentTaskSendParams;
    }) => api.r4cInstance.post<void>(`/documents/task/acceptSignedDocumentTakeover`, params),

    // predefined signatures CRUD
    createPredefinedSignature: (
      r4chost: string,
      token: string,
      data: {
        pngString: string;
        strokeData: string;
        descriptor: string;
      },
    ) => api.r4c.post<void>(r4chost, `/tablet/documents/predefined-signatures`, data),

    deletePredefinedSignature: (
      r4chost: string,
      token: string,
      data: {
        id: string;
      },
    ) => api.r4c.delete<void>(r4chost, `/tablet/documents/predefined-signatures`, data),

    getPredefinedSignatures: () =>
      api.r4cInstance.get<IDocumentPredefinedSignature[]>(`/tablet/documents/predefined-signatures`),

    setPredefinedSignaturesOrder: (params: { order: { id: number; order: number }[] }) =>
      api.r4cInstance.post<void>(`/tablet/documents/setPredefinedSignaturesOrder`, params),

    setCharlySharedAblageFiles: (params: { sharedAblageFiles: string[] }) =>
      api.r4cInstance.post<void>(`/tablet/setCharlySharedAblageFiles`, params),

    getDocumentRenderingTestsResults: (r4chost: string) =>
      api.r4c.get<any[]>(r4chost, `/tablet/getDocumentRenderingTestsResults`),
    runDocumentsRenderingTests: (r4chost: string, testId?: string) =>
      api.r4c.post<any[]>(r4chost, `/tablet/runDocumentsRenderingTests`, { testId }),
  };
}
