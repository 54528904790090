import { IFactoringPatientKomplett, IR4cPatientInfos } from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createPatientApi(api: RoseApi) {
  return {
    getPatientInfos: (params: {
      patient: IFactoringPatientKomplett;
      credentialsKey: string;
      noCache: boolean;
      benutzer: string;
      ctxid: string;
    }) => api.r4cInstance.post<IR4cPatientInfos>(`/patient/getPatientInfos`, params),
  };
}
