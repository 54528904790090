"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FEATUREVIEWS = exports.UNSELECTABLEVIEWS = exports.DEFAULTVIEWS = void 0;
const types_1 = require("../../types");
const views_1 = require("./views");
exports.DEFAULTVIEWS = [views_1.ViewID.PROFIL, views_1.ViewID.NEWS];
// these views depend on admin rights, not on selection
exports.UNSELECTABLEVIEWS = [views_1.ViewID.EINSTELLUNGEN_OLD, views_1.ViewID.EINSTELLUNGEN];
exports.FEATUREVIEWS = {
    [types_1.FEATURES.METRICS]: [
        views_1.ViewID.HEUTE,
        views_1.ViewID.BENCHMARK_OLD,
        views_1.ViewID.BENCHMARK,
        views_1.ViewID.UMSATZ_TABELLE,
        views_1.ViewID.UMSATZ_MATRIX,
        views_1.ViewID.UMSATZ_CHART,
        views_1.ViewID.TERMIN_QUALITAET,
        views_1.ViewID.HVM,
        views_1.ViewID.LEISTUNGEN_VUI,
        views_1.ViewID.PATIENTENUMSAETZE,
        views_1.ViewID.RECHNUNGEN,
        views_1.ViewID.HKP_MANAGER,
        views_1.ViewID.PA_MANAGER,
        views_1.ViewID.ABRECHNUNG,
        views_1.ViewID.PATIENTEN,
        views_1.ViewID.EXTENDEDQUERY,
        views_1.ViewID.COMPLIANCE,
        views_1.ViewID.PATIENTEN_PORTAL,
        views_1.ViewID.PATIENTEN_MAP,
        views_1.ViewID.ZIMMERBELEGUNG,
        ...exports.DEFAULTVIEWS,
    ],
    [types_1.FEATURES.BETA]: [views_1.ViewID.DASHBOARDS],
    [types_1.FEATURES.DOCTOSYNC]: [views_1.ViewID.DOCTOSYNC],
    [types_1.FEATURES.DSO]: [views_1.ViewID.DSO],
    [types_1.FEATURES.FACTORING]: [views_1.ViewID.FACTORING, views_1.ViewID.PATIENTEN_PORTAL],
    [types_1.FEATURES.FACTORINGEWE]: [views_1.ViewID.FACTORING, views_1.ViewID.PATIENTEN_PORTAL],
    [types_1.FEATURES.DOCUMENTS_SMALL]: [views_1.ViewID.PATIENTEN_PORTAL],
    [types_1.FEATURES.DOCUMENTS_BIG]: [views_1.ViewID.PATIENTEN_PORTAL],
    [types_1.FEATURES.DOCUMENTS_FLAT]: [views_1.ViewID.PATIENTEN_PORTAL],
    [types_1.FEATURES.ANAMNESE_WHITE]: [views_1.ViewID.PATIENTEN_PORTAL],
    [types_1.FEATURES.ANAMNESE_RED]: [views_1.ViewID.PATIENTEN_PORTAL],
    [types_1.FEATURES.ANAMNESE_BLACK]: [views_1.ViewID.PATIENTEN_PORTAL],
    [types_1.FEATURES.ANAMNESE_DIAMOND]: [views_1.ViewID.PATIENTEN_PORTAL],
    [types_1.FEATURES.PRAXISREPORT]: [views_1.ViewID.PRAXIS_KPI],
    [types_1.FEATURES.DEBUG]: [views_1.ViewID.DEBUG, views_1.ViewID.EINSTELLUNGEN_OLD, views_1.ViewID.DESIGNSYSTEM],
    [types_1.FEATURES.DEPRECATED]: [views_1.ViewID.RECHNUNGEN_OLD, views_1.ViewID.LEISTUNGEN_OLD],
};
