import { RoseApi } from '../apiTypes';
import {
  ILeistungenExtendedQueryParams,
  ILeistungenExtended,
  ITerminartenExtendedQueryParams,
  ITerminArtenStatistik,
  ITerminartenUmsaetzeExtendedQueryParams,
  ITerminArtenUmsaetzeSimpleMitClientInfos,
  IKZVAbrechnungExtendedQueryParams,
  IKZVAbrechnung,
  IBehandler,
  ITerminkontrolleExtendedQueryParams,
  ITerminKontrolleStatistik,
  IPatientenvorlaufExtendedQueryParams,
  IPatientenVorlaufDaten,
  IHKPReportExtendedQueryParams,
  IHKPBigReportResult,
  IMwStReportExtendedQueryParams,
  IMwStReportResult,
  IFaktorenReportExtendedQueryParams,
  IFaktorenReportResult,
  IPzrPatsWithMissingFollowUpsQueryParams,
  IPzrPatsWithMissingFollowUps,
} from '../../../../types';

export function createExtendedApi(api: RoseApi) {
  return {
    getLeistungenExtended: (params: ILeistungenExtendedQueryParams) =>
      api.metrics.post<{ leistungen: ILeistungenExtended[] }>('/api/extended/leistungen', params),
    getTerminartenExtended: (params: ITerminartenExtendedQueryParams) =>
      api.metrics.post<{ stats: ITerminArtenStatistik[] }>('/api/extended/terminarten', params),
    getTerminartenUmsaetzeExtended: (params: ITerminartenUmsaetzeExtendedQueryParams) =>
      api.metrics.post<ITerminArtenUmsaetzeSimpleMitClientInfos[]>('/api/extended/terminartenumsaetze', params),
    getKZVAbrechnungExtended: (params: IKZVAbrechnungExtendedQueryParams) =>
      api.metrics.post<IKZVAbrechnung[]>('/api/extended/kzvabrechnung', params),
    getBehandlerMitKontrolleExtended: () =>
      api.metrics.get<IBehandler['extid'][]>('/api/extended/behandlermitkontrolle'),
    getTerminkontrollenExtended: (params: ITerminkontrolleExtendedQueryParams) =>
      api.metrics.post<{ stats: ITerminKontrolleStatistik[] }>('/api/extended/terminkontrolle', params),
    getPatientenvorlaufExtended: (params: IPatientenvorlaufExtendedQueryParams) =>
      api.metrics.post<{ vorlauf: IPatientenVorlaufDaten[] }>('/api/extended/patientenvorlauf', params),
    getHKPReportExtended: (params: IHKPReportExtendedQueryParams) =>
      api.metrics.post<IHKPBigReportResult>('/api/extended/hkpreport', params),
    getMwStReportExtended: (params: IMwStReportExtendedQueryParams) =>
      api.metrics.post<IMwStReportResult>('/api/extended/mwstreport', params),
    getFaktorenReportExtended: (params: IFaktorenReportExtendedQueryParams) =>
      api.metrics.post<IFaktorenReportResult>('/api/extended/faktorenreport', params),
    getPzrPatientsNoFollowups: (params: IPzrPatsWithMissingFollowUpsQueryParams) =>
      api.metrics.post<IPzrPatsWithMissingFollowUps[]>('/api/extended/pzrpatients-nofollowups', params),
  };
}
