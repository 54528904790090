import { RoseApi } from './apiTypes';
import { createPatientApi } from './r4c/patientApi';
import { createAdminApi } from './r4c/adminApi';
import { createAnamnesetApi } from './r4c/anamneseApi';
import { createDocumentsApi } from './r4c/documentsApi';
import { createFactoringApi } from './r4c/factoringApi';
import { createR4cAppsApi } from './r4c/r4cAppsApi';
import { createR4cDebugApi } from './r4c/r4cDebugApi';
import { createPapoApi } from './r4c/papoApi';
import { createR4TabletApi } from './r4c/tabletApi';
import { createR4WorkflowsApi } from './r4c/workflowApi';

export function createR4cApi(roseApi: RoseApi) {
  return {
    documents: createDocumentsApi(roseApi),
    r4cApps: createR4cAppsApi(roseApi),
    admin: createAdminApi(roseApi),
    factoring: createFactoringApi(roseApi),
    tablet: createR4TabletApi(roseApi),
    anamnese: createAnamnesetApi(roseApi),
    debug: createR4cDebugApi(roseApi),
    papo: createPapoApi(roseApi),
    workflows: createR4WorkflowsApi(roseApi),
    patient: createPatientApi(roseApi),
  };
}
