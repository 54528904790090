import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import { defineComponent } from 'vue';
import { onboardingStore } from '@/state/onboardingStore';
export default defineComponent({
  props: ['stepId'],
  data: function data() {
    return {};
  },
  computed: {
    viewNeedsEdit: function viewNeedsEdit() {
      var _this = this;
      var step = onboardingStore.getters.shownStepsFlow.find(function (entry) {
        return entry.stepId === _this.stepId;
      });
      return (step === null || step === void 0 ? void 0 : step.complete) === false;
    }
  },
  methods: {
    jumpToView: function jumpToView() {
      onboardingStore.commit.setActiveStep(this.stepId);
    }
  }
});