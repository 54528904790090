import { isDev, isStaging } from './config';

export function modifyFaviconIfStaging() {
  if (isStaging || isDev) {
    modifyFavicon();
  }
}

// settings
const barHeightInPercent = 25;
const colorOne = isDev ? 'red' : 'yellow';
const colorTwo = isDev ? 'white' : 'black';
const howManyStripes = 10; // this should be even
const faviconSize = 32;

export function modifyFavicon() {
  const queryFavicon: HTMLLinkElement | null = document.querySelector('link[rel="icon"], link[rel="shortcut icon"]');

  if (!queryFavicon) {
    console.warn('[modifyFavicon] Favicon link element was not found');
    return;
  }

  const favicon = queryFavicon;

  const canvas = document.createElement('canvas');
  canvas.width = faviconSize;
  canvas.height = faviconSize;

  const context = canvas.getContext('2d') as CanvasRenderingContext2D;
  const img = document.createElement('img');
  img.src = favicon.href;

  img.onload = () => {
    // Draw Original Favicon as Background
    context.drawImage(img, 0, 0, faviconSize, faviconSize);

    // drawStripedBar(context);
    drawTriangle(context);

    // Replace favicon
    favicon.href = canvas.toDataURL('image/png');
  };
}

function drawTriangle(context: CanvasRenderingContext2D) {
  const triangleWidth = (barHeightInPercent / 100) * 2.5;
  context.beginPath();
  context.fillStyle = colorOne;
  context.moveTo(faviconSize * (1 - triangleWidth), faviconSize);
  context.lineTo(faviconSize, faviconSize);
  context.lineTo(faviconSize, faviconSize * (1 - triangleWidth));
  context.fill();
}

function drawStripedBar(context: CanvasRenderingContext2D) {
  const barHeight = barHeightInPercent / 100;
  context.beginPath();
  context.fillStyle = colorOne;
  context.rect(0, faviconSize * (1 - barHeight), faviconSize, faviconSize * barHeight);
  context.fill();

  const barWidth = (faviconSize * 1) / howManyStripes;
  context.fillStyle = colorTwo;
  for (let i = 0; i <= howManyStripes / 2; i++) {
    context.fillRect(2 * i * barWidth, faviconSize * (1 - barHeight), barWidth, faviconSize * barHeight);
  }
}
