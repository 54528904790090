import "core-js/modules/es.function.name.js";
import { defineComponent } from 'vue';
import { onboardingStore } from '@/state/onboardingStore';
import { rules } from '../../../common-ui';
import { isEmpty } from 'lodash';
export default defineComponent({
  data: function data() {
    return {
      isR4cOnboarding: onboardingStore.state.isR4cOnboarding,
      abweichendeVersandadresse: false,
      praxisDaten: {
        auftraggeber: {
          name: null,
          vorname: null,
          email: null,
          straße: null,
          stadt: null,
          plz: null
        },
        praxis: {
          name: null,
          nameKurz: null,
          tel: null,
          straße: null,
          stadt: null,
          plz: null
        }
      },
      formValid: false,
      rules: rules,
      pvs: 'unknown',
      pvsValid: false,
      empfehlungsAuswahl: ['Kollege', 'Mitarbeiter', 'Factoring-Anbieter', 'Social-Media', 'Websuche', 'solutio', 'EVIDENT', 'Sonstiges'],
      empfehlungSonstiges: ''
    };
  },
  computed: {
    viewIsValid: function viewIsValid() {
      return this.formValid && (this.pvsValid || onboardingStore.state.isR4cOnboarding);
    },
    activeStep: function activeStep() {
      return onboardingStore.state.activeStep;
    },
    empfehlung: function empfehlung() {
      return this.praxisDaten.empfehlung;
    }
  },
  watch: {
    viewIsValid: function viewIsValid() {
      this.$emit('viewValidUpdate', this.viewIsValid);
    },
    pvs: function pvs() {
      if (this.pvs && this.pvs !== 'unknown') {
        this.pvsValid = true;
      }
    },
    empfehlung: function empfehlung() {
      if (this.praxisDaten.empfehlung !== 'Sonstiges') {
        this.empfehlungSonstiges = '';
      }
    },
    activeStep: function activeStep() {
      var _a;
      if (((_a = this.activeStep) === null || _a === void 0 ? void 0 : _a.name) === 'Praxisdaten' && !isEmpty(this.empfehlungSonstiges)) {
        this.praxisDaten.empfehlung = 'Sonstiges';
      }
    }
  },
  methods: {
    next: function next() {
      this.$emit('next');
    },
    persistData: function persistData() {
      if (this.praxisDaten.empfehlung === 'Sonstiges') {
        this.praxisDaten.empfehlung = this.empfehlungSonstiges;
      }
      onboardingStore.commit.setPraxisdaten(this.praxisDaten);
      onboardingStore.commit.setLizenzInfo({
        pvs: this.pvs
      });
    },
    refreshHook: function refreshHook() {
      var _a;
      if (onboardingStore.state.isR4cOnboarding && onboardingStore.state.charlyPraxisInfo) {
        var praxisDatenForm = this.praxisDaten.praxis;
        praxisDatenForm.name = praxisDatenForm.name || onboardingStore.state.charlyPraxisInfo.praxis;
        praxisDatenForm.nameKurz = praxisDatenForm.nameKurz || onboardingStore.state.charlyPraxisInfo.praxis;
        praxisDatenForm.tel = praxisDatenForm.tel || onboardingStore.state.charlyPraxisInfo.telefon;
        praxisDatenForm.straße = praxisDatenForm.straße || onboardingStore.state.charlyPraxisInfo.stempelstrasse;
        praxisDatenForm.stadt = praxisDatenForm.stadt || onboardingStore.state.charlyPraxisInfo.stempelort;
        praxisDatenForm.plz = praxisDatenForm.plz || onboardingStore.state.charlyPraxisInfo.stempelplz;
        var auftraggeberForm = this.praxisDaten.auftraggeber;
        auftraggeberForm.name = auftraggeberForm.name || onboardingStore.state.charlyPraxisInfo.stempelname1;
        auftraggeberForm.vorname = auftraggeberForm.vorname || onboardingStore.state.charlyPraxisInfo.stempelname2;
        auftraggeberForm.straße = auftraggeberForm.straße || onboardingStore.state.charlyPraxisInfo.stempelstrasse;
        auftraggeberForm.stadt = auftraggeberForm.stadt || onboardingStore.state.charlyPraxisInfo.stempelort;
        auftraggeberForm.plz = auftraggeberForm.plz || onboardingStore.state.charlyPraxisInfo.stempelplz;
        auftraggeberForm.email = auftraggeberForm.email || onboardingStore.state.charlyPraxisInfo.email;
        this.praxisDaten.empfehlung = (_a = onboardingStore.state.praxisDaten) === null || _a === void 0 ? void 0 : _a.empfehlung;
      }
    }
  }
});