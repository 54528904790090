import { defineComponent } from 'vue';
export default defineComponent({
  components: {},
  props: ['gutscheinInfo', 'price'],
  computed: {
    sponsoredPrice: function sponsoredPrice() {
      var gi = this.gutscheinInfo.info;
      if (gi.prozentualerkundenerlass) {
        return this.price * gi.prozentualerkundenerlass;
      }
      if (gi.absoluterkundenerlass) {
        return this.price - gi.absoluterkundenerlass;
      }
      return this.price;
    }
  }
});