import { roseDayjs } from '../../../base';

export const tzFilter = {
  filters: {
    tz(date: Date | string, format = 'DD.MM.YYYY', timezone = 'GMT'): string {
      if (date) {
        const m = roseDayjs(date);
        if (m.isValid()) {
          return m.tz(timezone).format(format);
        }
        return 'n/a';
      }
      return '-';
    },
    t(date: Date | string, format = 'DD.MM.YYYY'): string {
      if (date) {
        const m = roseDayjs(date);
        if (m.isValid()) {
          return m.format(format);
        }
        return 'n/a';
      }
      return '-';
    },
  },
};
