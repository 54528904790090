import { RoseApi } from '../apiTypes';
import { IHKPResult, IPARCaseInfo, IHKPManagerBase, IHKPQueryParams, UIHKP } from '../../../../types';

export function createHKPApi(api: RoseApi) {
  return {
    getHKP: (extid: string) => api.metrics.get<UIHKP>(`/api/hkp`, { query: { extid } }),
    getHKPs: (params: IHKPQueryParams) => api.metrics.get<IHKPResult>(`/api/hkp`, { query: params }),
    getPARCases: (patids: string[]) => api.metrics.post<IPARCaseInfo[]>(`/api/hkp/parcases`, { patids }),
    saveHKPManagerInfo: (hkp: IHKPManagerBase) => api.metrics.post<void>(`/api/hkp/manager`, hkp),
    getFilteredHkp: (params: { filterkey: string; behandler?: string }) =>
      api.metrics.get<{ hkpCount: number; patientCount: number; totalSum: number }>(
        `/api/hkp/filtered/${params.filterkey}`,
        { query: params },
      ),
  };
}
