import { IOnboardingEntry, IOnboardingEntryExtended, IProfile } from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createOnboardingApi(api: RoseApi) {
  return {
    getOnboardingEntries: () =>
      api.metrics.get<{ onboardingEntries: IOnboardingEntryExtended[]; unacceptedProfiles: IProfile[] }>(
        '/admin/onboarding',
      ),
    patchOnboardingEntry: (id: string, entry: Partial<IOnboardingEntry>) =>
      api.metrics.patch<IOnboardingEntry>(`/admin/onboarding/entries/${id}`, entry),
    patchOnboardingEntries: (entries: (Partial<IOnboardingEntry> & { id: IOnboardingEntry['id'] })[]) =>
      api.metrics.patch<IOnboardingEntry[]>('/admin/onboarding/entries', entries),
  };
}
