"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tabletI18nMessages = void 0;
exports.tabletI18nMessages = {
    // PARTIAL translations for patient facing views
    tr: {
        patientFacing: {
            words: {
                tablet: 'tablet',
                reset: 'Sıfırla',
                back: 'geri',
                page: 'sayfa',
            },
            consentAgreement: {
                cancelTask: 'İşlemi iptal et',
                cancelTaskConfirm: 'İşlemi iptal etmek istediğinizden emin misiniz?',
                invalidDobNoEwePossible: 'Geçersiz doğum tarihi. EWE mümkün değil.',
                editFurther: 'daha fazla düzenle',
                stillThere: 'Hala orda mısın?',
                idleWarning: 'İşlem, etkileşim olmadan otomatik olarak iptal edilir',
                plsSignBelow: 'Lütfen aşağıdan imzalayın',
                plsContinueBelow: 'İşlemi sürdürmek için lütfen aşağı kaydırın',
                showArt14: `Veri koruma beyanını Madde 14 GDPR'ye göre gösterin`,
                sendCopyToMyMailStepHeader: 'Bir kopyasını e-posta adresime gönder',
                sendCopyToMyMail: 'Bir kopyasını e-posta adresime gönder',
                send: 'gönderme',
                signature: 'Imza',
            },
        },
        home: {
            waitForTask: 'Onay isteğini bekleyin...',
            tabletNotRegistered: `Bu tablet henüz Rose'a kayıtlı değil`,
            tabletRegistered: `Bu tablet Rose'a başarıyla kaydedildi`,
        },
        art14: {
            chooseRz: 'Bir veri merkezi seçin',
        },
    },
    ru: {
        patientFacing: {
            words: {
                tablet: 'Планшет',
                reset: 'Сброс',
                back: 'Назад',
                page: 'Страница',
            },
            consentAgreement: {
                cancelTask: 'Прекратить процесс',
                cancelTaskConfirm: 'Вы правда хотите прекратить процесс?',
                invalidDobNoEwePossible: 'Неверная дата рождения. EWE невозможно.',
                editFurther: 'Обрабатывать дальше',
                stillThere: 'Вы ещё здесь?',
                idleWarning: 'Без взаимодействия процесс прекращается автоматически',
                plsSignBelow: 'Распишитесь внизу, пожалуйста',
                plsContinueBelow: 'Прокрутите вниз, чтобы продолжить процесс',
                showArt14: `Показать руководство по защите данных`,
                sendCopyToMyMailStepHeader: 'Послать мне копию электронной почты',
                sendCopyToMyMail: 'Послать мне копию электронной почты',
                send: 'Отправь',
                signature: 'Подпись',
            },
        },
        home: {
            waitForTask: 'Ожидаются запросы соглашения...',
            tabletNotRegistered: 'Планшет пока не был зарегистрирован в системе "Rose"',
            tabletRegistered: 'Планшет успешно зарегистрировано в системе "Rose"',
        },
        art14: {
            chooseRz: 'Выберите вычислительный центр',
        },
    },
    it: {
        patientFacing: {
            words: {
                tablet: 'Tablet',
                reset: 'Ripristina',
                back: 'Indietro',
                page: 'Pagina',
            },
            consentAgreement: {
                cancelTask: 'Annulla processo',
                cancelTaskConfirm: 'Vuole davero interompere il processo?',
                invalidDobNoEwePossible: 'Data di nascita non valida. EWE non possibile.',
                editFurther: 'Modifica ulterioremente',
                stillThere: 'Lei e`ancora li?',
                idleWarning: 'Il processo viene automaticamente terminatosenza interazione.',
                plsSignBelow: 'Per favore firmare sotto.',
                plsContinueBelow: 'Si prega di scorrere verso il basso per continuare il processo.',
                showArt14: `Mostra la dichiarazionesulla protezionedei datiai sensi dell'art 14 DSGVO`,
                sendCopyToMyMailStepHeader: 'Invia una copiaal mio indirizzo E-mail',
                sendCopyToMyMail: 'Invia una copiaal mio indirizzo E-mail',
                send: 'Invia',
                signature: 'Firma',
            },
        },
        home: {
            waitForTask: 'Attendere la risposta di aprovazione...',
            tabletNotRegistered: 'Questo tablet non e`stato ancora registrato con Rose',
            tabletRegistered: 'Questo tablet è stato registrato con successo su Rose',
        },
        art14: {
            chooseRz: 'Scelga una banca dati.',
        },
    },
    es: {
        patientFacing: {
            words: {
                tablet: 'Tableta',
                reset: 'Resetear',
                back: 'Atrás',
                page: 'Página',
            },
            consentAgreement: {
                cancelTask: 'Cancelar el proceso',
                cancelTaskConfirm: 'De verdad desea cancelar el proceso?',
                invalidDobNoEwePossible: 'Fecha de nacimiento inválida. No es posible EWE.',
                editFurther: 'Seguir el proceso',
                stillThere: 'Todavía está aquí?',
                idleWarning: 'Sin interacción el proceso se anulará automaticamente.',
                plsSignBelow: 'Firme abajo',
                plsContinueBelow: 'Por favor, desplácese hacia abajo para continuar el proceso.',
                showArt14: 'Mostrar declaración de privacidad según art. 14 DSGVO',
                sendCopyToMyMailStepHeader: 'Enviar copia a mi correo electrónico',
                sendCopyToMyMail: 'Enviar copia a mi correo electrónico',
                send: 'Enviar',
                signature: 'Firma',
            },
        },
        home: {
            waitForTask: 'Esperando a la respuesta de consentimiento...',
            tabletNotRegistered: 'Esta tableta todavía no se ha registrado en rose',
            tabletRegistered: 'Esta tableta se ha registrado con succeso en rose',
        },
        art14: {
            chooseRz: 'Seleccione un centro de datos',
        },
    },
    // FULL translations de,en
    de: {
        Lädt: 'Lädt',
        'Unterschrieben von': 'Unterschrieben von',
        patientFacing: {
            restartTask: 'Vorgang neu starten?',
            words: {
                tablet: 'Tablet',
                reset: 'Tablet abmelden',
                back: 'Zurück',
                page: 'Seite',
            },
            consentAgreement: {
                cancelTask: 'Vorgang abbrechen',
                cancelTaskConfirm: 'Wollen Sie den Vorgang wirklich abbrechen?',
                invalidDobNoEwePossible: 'Ungültiges Geburtsdatum. Keine EWE möglich.',
                editFurther: 'Weiter bearbeiten',
                stillThere: 'Sind Sie noch da?',
                idleWarning: 'Ohne Interaktion wird der Vorgang automatisch abgebrochen.',
                plsSignBelow: 'Zum Unterschreiben runter scrollen und im Unterschriftenfeld unterschreiben',
                plsContinueBelow: 'Bitte scrollen Sie nach unten, um den Vorgang fortzusetzen.',
                showArt14: 'Datenschutzerklärung gemäß Art. 14 DSGVO anzeigen',
                sendCopyToMyMailStepHeader: 'Kopie an meine E-Mail-Adresse senden',
                sendCopyToMyMail: 'Kopie an meine E-Mail-Adresse senden',
                mailSharingInfo: 'Wir senden Ihnen einen Link zum Download des Dokuments per E-Mail. Vor dem Herunterladen fragen wir Sie nach diesen Daten, um Ihre Dokumente vor unbefugtem Zugang zu schützen.',
                mailSharingInfoName: 'Vorname',
                mailSharingInfoDOB: 'Geburtstag',
                send: 'Absenden',
                signature: 'Unterschrift (bei Minderjährigen gesetzlicher Vertreter)',
            },
        },
        home: {
            successfulTaskCompletionText: 'Eingaben wurden gespeichert. Danke!',
            waitForTask: 'Tablet kann zurückgegeben werden, es wartet nun auf weitere Aufgaben...',
            tabletNotRegistered: 'Dieses Tablet wurde noch nicht bei rose registriert',
            tabletRegistered: 'Dieses Tablet wurde erfolgreich bei rose registriert',
        },
        art14: {
            chooseRz: 'Wählen Sie ein Rechenzentrum',
        },
        registerNew: {
            ios: {
                openMenu: 'Teilen-Menü oben rechts öffnen',
                chooseAddToHomescreen: '"Zum Home-Bildschirm" auswählen',
                add: 'Hinzufügen',
            },
            android: {
                openMenu: 'Menü oben rechts öffnen',
                chooseAddToHomescreen: '"Zum Start-Bildschirm zufügen" auswählen',
                add: 'Hinzufügen',
            },
            successfulRegistered: 'Erfolgreich registriert!',
            alreadySuccessfulRegistered: 'Bereits erfolgreich registriert!',
            plsAddTabletToStartScreen: 'Bitte fügen Sie die Seite zum Startbildschirm hinzu',
            afterThatYouCanCloseTab: 'Danach können Sie diesen Tab wieder schließen.',
            useInBrowser: 'Im Browser verwenden',
            plsWait: 'Bitte warten Sie..',
            errorRegister: 'Das Tablet konnte nicht registriert werden!',
            errorValidation: 'Die Tablet Registrierung konnte nicht validiert werden!',
            videoLink: 'Schauen Sie sich unsere Hilfevideos an!',
        },
        welcome: {
            deviceNotRegistered: 'Dieses Gerät wurde nicht im rose-System registriert.',
            informYourself: 'Informieren Sie sich auf',
            aboutOurProducts: 'über unsere Produkte.',
            goToRoseDental: 'Weiter zu rose.dental',
            deviceNoLongererRegistered: 'Diese App ist nicht mehr im rose-System registriert.',
            plsRemoveAppAndReregister: 'Bitte entfernen Sie diese App und registrieren dieses Tablet erneut.',
        },
    },
    en: {
        'Unterschrieben von': 'Signed by',
        Lädt: 'Loading',
        patientFacing: {
            restartTask: 'Restart task?',
            words: {
                tablet: 'Tablet',
                reset: 'Unregister Tablet',
                back: 'go back',
                page: 'Page',
            },
            consentAgreement: {
                cancelTask: 'Cancel process',
                cancelTaskConfirm: 'Do you really want to abort the process?',
                invalidDobNoEwePossible: 'Invalid date of birth. No EWE possible.',
                editFurther: 'Continue processing',
                stillThere: 'Are you still there?',
                idleWarning: 'Without interaction the process is automatically aborted.',
                plsSignBelow: 'Please sign below',
                plsContinueBelow: 'Please scroll down to continue the process.',
                showArt14: 'Show privacy policy according to Art. 14 DSGVO',
                sendCopyToMyMailStepHeader: 'Send a copy to my email address',
                sendCopyToMyMail: 'Send a copy to my email address',
                mailSharingInfo: 'We will send a link to the document via email. To download, you need to enter this information:',
                mailSharingInfoName: 'Name',
                mailSharingInfoDOB: 'Date of birth',
                send: 'Submit',
                signature: 'Signature (in case of minors legal representative)',
            },
        },
        home: {
            successfulTaskCompletionText: 'Your information has been saved. Thank you very much!',
            waitForTask: 'Tablet can be returned, it is now waiting for further tasks...',
            tabletNotRegistered: 'This tablet has not yet been registered at rose',
            tabletRegistered: 'This tablet was successfully registered at rose',
        },
        art14: {
            chooseRz: 'Choose a factoring center',
        },
        registerNew: {
            successfulRegistered: 'Successfully registered!',
            alreadySuccessfulRegistered: 'Already successfully registered!',
            plsAddTabletToStartScreen: 'Please add the page to the home screen',
            afterThatYouCanCloseTab: 'Afterwards you can close this tab.',
            useInBrowser: 'Use in browser',
            openMenu: 'Open menu',
            chooseAddToHomescreen: 'select "Add to Home screen"',
            add: 'Add',
            plsWait: 'Please wait..',
            errorRegister: 'The tablet could not be registered!',
            errorValidation: 'The tablet registration could not be validated!',
        },
        welcome: {
            deviceNotRegistered: 'This device was not registered in the rose system.',
            informYourself: 'Inform yourself on',
            aboutOurProducts: 'about our products.',
            goToRoseDental: 'Continue to rose.dental',
            deviceNoLongererRegistered: 'This app is no longer registered in the rose system.',
            plsRemoveAppAndReregister: 'Please remove this app and register this tablet again.',
        },
    },
};
