import { RoseApi } from '../apiTypes';

export function createR4cDebugApi(api: RoseApi) {
  return {
    htmlToPdfDebug: (htmlString: string, options?: any) =>
      api.r4cDispatch.post<string>(`/debug/htmlToPdf`, {
        htmlString,
        ...options,
      }),
    htmlToPdfDebugR4cHost: (htmlString: string, r4chost: string, options?: any) =>
      api.r4c.post<string>(r4chost, `/debug/htmlToPdf`, { htmlString, ...options }),
  };
}
