import { TimeItem } from '../../base';
import { ADMIN_LEVEL, IZeitenBase, BenchmarkConfigUnitType } from '../../types';
import _, { sample } from 'lodash';
import numeral from 'numeral';
import { registerNumeralLocale } from './numeralLocale';

registerNumeralLocale(numeral);

export interface IHKPMetaCompare {
  frist?: Date;
  hkpid: string;
}

export function hkpMetaComparer(a?: IHKPMetaCompare, b?: IHKPMetaCompare) {
  let i = 0;
  if (a && b) {
    if (a.frist && b.frist) {
      i = new Date(a.frist).valueOf() - new Date(b.frist).valueOf();
    } else {
      if (a.frist) {
        i = 1;
      } else {
        if (b.frist) {
          i = -1;
        } else {
          // both frist === undefined
          i = a.hkpid.localeCompare(b.hkpid);
        }
      }
    }
  } else {
    if (a) {
      i = 1;
    } else if (b) {
      i = -1;
    }
  }
  return i;
}

export function wait(time: number) {
  return new Promise<void>(resolve => setTimeout(() => resolve(), time));
}

export async function forever(cb: () => Promise<any>, cancel: () => boolean) {
  while (!cancel()) {
    await cb();
  }
}

export function recurring(cb: () => Promise<any>, initialWait = 0) {
  let cancelled = false;
  setTimeout(() => {
    if (!cancelled) {
      forever(cb, () => cancelled);
    }
  }, initialWait);
  return () => (cancelled = true);
}

export const wochentage = [
  { id: 'mo', name: 'Montag', nr: 1 },
  { id: 'di', name: 'Dienstag', nr: 2 },
  { id: 'mi', name: 'Mittwoch', nr: 3 },
  { id: 'do', name: 'Donnerstag', nr: 4 },
  { id: 'fr', name: 'Freitag', nr: 5 },
  { id: 'sa', name: 'Samstag', nr: 6 },
  { id: 'so', name: 'Sonntag', nr: 7 },
];

export function getStunden(oz: IZeitenBase) {
  try {
    const von = TimeItem.parse(oz.von);
    const bis = TimeItem.parse(oz.bis);
    if (von && bis) {
      return TimeItem.diff(bis, von);
    }
  } catch (err) {
    console.error(err);
  }
  return 0;
}

export function arrayMove<T>(arr: T[], fromIndex: number, toIndex: number) {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}

// log without reactive getters/setters
export function logVueReactiveObject(name: string, obj: any) {
  console.log(name, unreactiveVueObject(obj));
}

export function unreactiveVueObject(obj: any) {
  try {
    return JSON.parse(JSON.stringify(obj));
  } catch (e) {
    // if circular references
    return obj;
  }
}

export function adminLevelString(level: ADMIN_LEVEL): string {
  switch (level) {
    case ADMIN_LEVEL.NONE:
      return 'Normaler Benutzer';
    case ADMIN_LEVEL.PRAXISMANAGER:
      return 'Admin ohne Benutzerverwaltung';
    case ADMIN_LEVEL.CLIENT_ADMIN:
      return 'Admin mit Benutzerverwaltung';
    case ADMIN_LEVEL.ROSE_TEAM:
      return 'rose-Team';
    case ADMIN_LEVEL.SOLUTIO_TEAM:
      return 'solutio-Team';
  }
}

declare global {
  interface Document {
    startViewTransition(cb: () => void): {
      finished: Promise<void>;
      ready: Promise<void>;
      updateCallbackDone: Promise<void>;
    };
  }
}

export function navigateWithViewTransition(performNavigation: CallableFunction) {
  // causes crashes on chrome as of 2024-08
  const viewTransitionSupported = false && !!document.startViewTransition;
  if (!viewTransitionSupported) {
    performNavigation();
    return;
  }

  return document.startViewTransition(() => {
    performNavigation();
  });
}

const germanTitles = [
  'Dr.',
  'Prof. Dr.',
  'Prof.',
  'Dipl.-Ing.',
  'Dr. Ing.',
  'Dr. med.',
  'Dr. rer. nat.',
  'Dr. phil.',
  'Dr. jur.',
  'Dr. oec.',
  'Dr. rer. pol.',
  'Dr. theol.',
  'Dipl.-Kfm.',
  'Dipl.-Psych.',
  'Dipl.-Soz.',
];

export function fakeTitle(onlyDr = false) {
  if (onlyDr) {
    return Math.random() < 0.5 ? undefined : 'Dr.';
  }
  return Math.random() < 0.5 ? undefined : sample(germanTitles);
}

export function benchmarkGetFormattedValue(
  value: number,
  unitUsedForFormatting: BenchmarkConfigUnitType,
  formatHint?: string,
) {
  if (value || value === 0) {
    let sign = '';
    let fallbackFormat = '0,0';
    switch (unitUsedForFormatting) {
      case BenchmarkConfigUnitType.EURO:
        fallbackFormat = '0,0';
        break;
      case BenchmarkConfigUnitType.HOURS:
      case BenchmarkConfigUnitType.HOURSMINUTES:
      case BenchmarkConfigUnitType.MINUTES:
        sign = value < 0 ? '-' : sign;
        fallbackFormat = '00:00:00';
        value = 60 * Math.abs(value); // convert to absolute s
        break;
      case BenchmarkConfigUnitType.TERMINE:
        fallbackFormat = '0,0';
        break;
      case BenchmarkConfigUnitType.PATIENTEN:
      case BenchmarkConfigUnitType.DAYS:
      case BenchmarkConfigUnitType.YEARS:
        break;
    }
    let ret = sign + numeral(value).format(formatHint || fallbackFormat) || '';
    if (
      [BenchmarkConfigUnitType.HOURS, BenchmarkConfigUnitType.HOURSMINUTES, BenchmarkConfigUnitType.MINUTES].includes(
        unitUsedForFormatting,
      )
    ) {
      const parts = _.split(ret, ':');
      ret = `${numeral(parts[0]).format('0,0')}:${parts[1]}`;
    }
    return ret;
  }
  return '';
}
