"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.kzvLUT = exports.createFeatureToPriceMap = exports.defaultUserRights = exports.EPS_RENDERING_SCALE_FACTOR = exports.PDF_RENDERING_SCALE_FACTOR = exports.dzrOrgUnitResolve = exports.dzrAbzOrgUnits = exports.dzrOrgUnits = void 0;
const types_1 = require("../../types");
exports.dzrOrgUnits = [
    { id: 32, name: 'DZRN', lang: 'DZR Deutsches Zahnärztliches Rechenzentrum GmbH, Neuss (32)' },
    { id: 64, name: 'DZRS', lang: 'DZR Deutsches Zahnärztliches Rechenzentrum GmbH, Stuttgart (64)' },
    { id: 128, name: 'DZRF', lang: 'DZR Deutsches Zahnärztliches Rechenzentrum GmbH, FRH (128)' },
    { id: 256, name: 'DZRH', lang: 'DZR Deutsches Zahnärztliches Rechenzentrum GmbH, Hamburg (256)' },
];
exports.dzrAbzOrgUnits = [{ id: 8, name: 'ABZ', lang: 'ABZ Zahnärztliches Rechenzentrum für Bayern GmbH (8)' }];
function dzrOrgUnitResolved() {
    const ret = {};
    for (const ou of exports.dzrOrgUnits) {
        ret[ou.id] = ou.name;
    }
    return ret;
}
exports.dzrOrgUnitResolve = dzrOrgUnitResolved();
exports.PDF_RENDERING_SCALE_FACTOR = 3;
exports.EPS_RENDERING_SCALE_FACTOR = 200; // dpi
exports.defaultUserRights = {
    email: '',
    cid: '',
    adminLevel: types_1.ADMIN_LEVEL.CLIENT_ADMIN,
    views: [],
    extids: [],
    isExternApiOnly: false,
    notificationSettings: {
        emails: { main: { active: false } },
        todos: {
            active: false,
            days: {
                mo: false,
                di: false,
                mi: false,
                do: false,
                fr: false,
                sa: false,
                so: false,
            },
            sortDate: 'asc',
            updateStyle: 'full',
        },
        hkp: {
            tageVorFristablauf: {
                active: false,
            },
            beiFristablauf: false,
            nurEigeneFristen: false,
        },
    },
    viewSpecificBehandlerAccess: {},
    ipfiltering: {
        active: false,
    },
};
const oldMetricsPrices = [
    'plan_Fs2CT2BNmeSmzi',
    'plan_Fs2StaTKULETw2',
    'plan_GI9GUDW8KNkQB3',
    'plan_GI9GYvDiZPrA7m',
    'price_1NIWk9KCumfvsWbQE79Ppr38',
];
const extraFactoringSchnittstellePrice = 'price_1JP7n2KCumfvsWbQwDUhP2WL';
const dataWarehouse15Price = 'price_1OoWu4KCumfvsWbQOzGIungM';
const dataWarehouse25Price = 'price_1LhsqmKCumfvsWbQ0VCwXURS';
const r2csvPrice = 'price_1M8298KCumfvsWbQ9W7RWI8e';
function createFeatureToPriceMap(stripeCfg) {
    const ROSE_FEATURE_TO_STRIPE_PLAN = {
        [types_1.FEATURES.METRICS]: [stripeCfg.rose_metrics, stripeCfg.charly_analytics, ...oldMetricsPrices],
        [types_1.FEATURES.MULTICLIENT]: [stripeCfg.rose_metrics_multiclientlogin],
        [types_1.FEATURES.FACTORING]: [stripeCfg.rose_plan_factoring],
        [types_1.FEATURES.FACTORINGEWE]: [stripeCfg.rose_plan_factoring_ewe],
        [types_1.FEATURES.DOCUMENTS_SMALL]: [stripeCfg.rose_plan_document_signer_small_volume],
        [types_1.FEATURES.DOCUMENTS_BIG]: [
            stripeCfg.rose_plan_document_signer_big_base,
            stripeCfg.rose_plan_document_signer_big_volume,
        ],
        [types_1.FEATURES.DOCUMENTS_FLAT]: [stripeCfg.rose_plan_document_signer_flat],
        [types_1.FEATURES.ANAMNESE_WHITE]: [stripeCfg.rose_plan_anamnese_white_volume, 'price_1MddYuKCumfvsWbQJtetKIA4'],
        [types_1.FEATURES.ANAMNESE_RED]: [stripeCfg.rose_plan_anamnese_red_base, stripeCfg.rose_plan_anamnese_red_volume],
        [types_1.FEATURES.ANAMNESE_BLACK]: [stripeCfg.rose_plan_anamnese_black],
        [types_1.FEATURES.ANAMNESE_DIAMOND]: [stripeCfg.rose_plan_anamnese_diamond],
        [types_1.FEATURES.EXTRAFACTORINGSCHNITTSTELLE]: [extraFactoringSchnittstellePrice],
        [types_1.FEATURES.DATAWAREHOUSE15]: [dataWarehouse15Price],
        [types_1.FEATURES.DATAWAREHOUSE25]: [dataWarehouse25Price],
        [types_1.FEATURES.R2CSV]: [r2csvPrice],
    };
    return ROSE_FEATURE_TO_STRIPE_PLAN;
}
exports.createFeatureToPriceMap = createFeatureToPriceMap;
exports.kzvLUT = {
    '1': 'KZV Stuttgart',
    '2': 'KZV Baden-Württemberg',
    '3': 'KZV Regierungsbezirk Tübingen',
    '4': 'KZV Niedersachsen',
    '5': 'KZV Regierungsbezirk Karlsruhe',
    '6': 'KZV Rheinland-Pfalz',
    '7': 'KZV Regierungsbezirk Freiburg',
    '01': 'KZV Stuttgart',
    '02': 'KZV Baden-Württemberg',
    '03': 'KZV Regierungsbezirk Tübingen',
    '04': 'KZV Niedersachsen',
    '05': 'KZV Regierungsbezirk Karlsruhe',
    '06': 'KZV Rheinland-Pfalz',
    '07': 'KZV Regierungsbezirk Freiburg',
    '11': 'KZV Bayern',
    '13': 'KZV Nordrhein',
    '20': 'KZV Hessen',
    '30': 'KZV Berlin',
    '31': 'KZV im Lande Bremen',
    '32': 'KZV Hamburg',
    '35': 'KZV Saarland',
    '36': 'KZV Schleswig-Holstein',
    '37': 'KZV Westfalen-Lippe',
    '52': 'KZV Mecklenburg-Vorpommern',
    '53': 'KZV Land Brandenburg',
    '54': 'KZV Sachsen-Anhalt',
    '55': 'KZV Thüringen',
    '56': 'KZV Sachsen',
};
