import { UmsatzMonthResult, IUmsatzPlainQueryResult, UmsatzYearResult, UmsatzQuarterResult } from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createUmsatzApi(api: RoseApi) {
  return {
    chartMonth: (params: { from: string; to: string; behandler?: string; onlyPzr?: boolean; onlyZa?: boolean }) =>
      api.metrics.get<UmsatzMonthResult>(`/api/umsatz/chart/month`, { query: params }),
    chartQuarter: (params: { from: string; to: string; behandler?: string; onlyPzr?: boolean; onlyZa?: boolean }) =>
      api.metrics.get<UmsatzQuarterResult>(`/api/umsatz/chart/quarter`, { query: params }),
    chartYear: (params: { from: string; to: string; behandler?: string; onlyPzr?: boolean; onlyZa?: boolean }) =>
      api.metrics.get<UmsatzYearResult>(`/api/umsatz/chart/year`, { query: params }),
    umsatzPlain: (params: { from: string; to: string; cid?: string }) =>
      api.metrics.get<IUmsatzPlainQueryResult>(`/api/umsatz/plain`, { query: params }),
  };
}
