import { RoseApi } from '../apiTypes';
import { ILabelConfig, IHkpLabels } from '../../../../types';

export function createHkpLabelApi(api: RoseApi) {
  return {
    saveLabelConfig: (labelConfig: ILabelConfig[]) =>
      api.metrics.post(`/api/hkplabels/savelabelconfig`, { labelConfig }),
    saveHkpLabels: (hkpid: string, labelConfig: ILabelConfig[]) =>
      api.metrics.post(`/api/hkplabels/saveHkplabels`, { hkpid, selectedLabels: labelConfig }),
    getHkpLabelsForClient: () => api.metrics.get<IHkpLabels[]>(`/api/hkplabels/allClientLabels`),
  };
}
