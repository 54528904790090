import {
  IEweTaskSendParams,
  IFactoringClientRechenzentren,
  IFactoringClientRechenzentrumDB,
  IFactoringPatientKomplett,
  IFactoringPraxis,
} from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createFactoringApi(api: RoseApi) {
  return {
    getClientRechenzentren: (r4chost: string, secretOverride = '', includeVeraltet = false) =>
      api.r4c.get<IFactoringClientRechenzentren<IFactoringClientRechenzentrumDB>>(
        r4chost,
        `/factoring/clientrechenzentren`,
        {
          query: { secret: secretOverride, ...(includeVeraltet ? { includeVeraltet: '1' } : {}) },
        },
      ),
    getPraxisDaten: (secretOverride = '', includeVeraltet = false) =>
      api.r4cDispatch.get<IFactoringPraxis>(`/factoring/praxisdaten`, {
        query: { secret: secretOverride, ...(includeVeraltet ? { includeVeraltet: '1' } : {}) },
      }),
    updateClientRechenzentren: (
      r4chost: string,
      params: { rechenzentren: IFactoringClientRechenzentrumDB[]; secret?: string },
    ) => api.r4c.post<void>(r4chost, `/factoring/clientrechenzentren`, params),

    receiveConsentAgreementShared: (params: { shareEntryId: string; response: IEweTaskSendParams }) =>
      api.r4cInstance.post(`/factoringinit/receiveConsentAgreementShared`, params),

    receiveConsentAgreementTakeover: (params: {
      takeoverSecret: string;
      taskId: string;
      response: IEweTaskSendParams;
    }) => api.r4cInstance.post(`/factoringinit/receiveConsentAgreementTakeover`, params),

    receiveConsentAgreement: (params: { token: string; taskId: string | undefined; response: IEweTaskSendParams }) =>
      api.r4cInstance.post(`/factoringinit/receiveConsentAgreement`, params),

    previewEweDocument: (params: { patient: IFactoringPatientKomplett; rzkey: string; language: string }) =>
      api.r4cInstance.post<Blob>(`/factoringinit/previewEweDocument`, params, {
        responseType: 'blob',
      }),

    downloadEweDocument: (params: { patient: IFactoringPatientKomplett; rzkey: string; language: string }) =>
      api.r4cInstance.post<Blob>(`/factoringinit/downloadEweDocument`, params, {
        responseType: 'blob',
      }),

    initializeDesktopR4c: () =>
      api.r4cDispatch.post<{ cid: string; r4chost: string; praxisName: string }>(`/factoring/initializeDesktopR4c`),
  };
}
