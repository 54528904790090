import { VCardTitle } from 'vuetify/lib/components/VCard';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "wrap"
  }, [_c(VCardTitle, [_c('div', {
    staticClass: "done"
  }, [_c('span', [_vm._v("Vielen Dank!")]), _vm.isR4cOnboarding ? _c('div', {
    staticClass: "fertig-r4c"
  }, [_c('div', [_vm._v("Gehe jetzt zurück zum charly. Dort wird r4c gleich aktiviert.")]), _c('div', [_vm._v("Falls das länger als 1 Minute dauert, starte bitte den charly-Client einmal neu.")]), _c('div', [_vm._v("Lies dir unsere "), _c('a', {
    attrs: {
      "href": "https://r4c.demo.rose.dental/help",
      "target": "_blank"
    }
  }, [_vm._v("Hilfe")]), _vm._v(" durch, dort gibt es auch Videos zur schnellen Einführung.")])]) : _c('div', {
    staticClass: "fertig-metrics"
  }, [_vm._v("Zurück zur "), _c('a', {
    attrs: {
      "href": "https://www.rose.dental"
    }
  }, [_vm._v("Homepage")])])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };