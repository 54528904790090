import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.shownStepsFlow.length ? _c('div', {
    staticClass: "main-wrap"
  }, [_c(VStepper, {
    staticClass: "stepper",
    attrs: {
      "id": "stepper"
    },
    model: {
      value: _vm.shownStepNumber,
      callback: function callback($$v) {
        _vm.shownStepNumber = $$v;
      },
      expression: "shownStepNumber"
    }
  }, [_c(VStepperHeader, [_vm._l(_vm.shownStepsFlow, function (step, index) {
    return [_c(VStepperStep, {
      key: index,
      attrs: {
        "step": index + 1,
        "complete": index <= _vm.maxStepIndexReached,
        "editable": !step.skipped && !_vm.fullState.done && index <= _vm.maxStepIndexReached,
        "rules": [function () {
          return step.skipped || index + 1 > _vm.maxStepIndexReached || _vm.shownStepsFlow[index].complete;
        }]
      }
    }, [_vm._v(_vm._s(step.name)), step.skipped ? _c('small', [_vm._v("Übersprungen")]) : _vm._e(), step.mustAccept && step.complete ? _c('small', [_vm._v("Akzeptiert")]) : _vm._e(), step.stepId === _vm.StepId.paket && _vm.fullState.lizenzInfo ? _c('small', [_vm._v(_vm._s(_vm.fullState.lizenzInfo.paket.name))]) : _vm._e()]), index < _vm.shownStepsFlow.length - 1 ? _c(VDivider, {
      key: index + 1000
    }) : _vm._e()];
  })], 2), _c(VStepperItems, _vm._l(_vm.shownStepsFlow, function (step, index) {
    return _c(VStepperContent, {
      key: index,
      staticClass: "content-wrap",
      class: {
        musstAccept: step.mustAccept
      },
      attrs: {
        "step": index + 1
      }
    }, [_c('div', {
      staticClass: "view-wrap"
    }, [_c(step.component, {
      ref: "activeView",
      refInFor: true,
      tag: "component",
      attrs: {
        "viewParams": step.viewParams,
        "stepperIndex": index
      },
      on: {
        "next": function next($event) {
          return _vm.nextStep(step, $event);
        },
        "viewValidUpdate": function viewValidUpdate($event) {
          return _vm.viewValidUpdate(step, $event);
        },
        "goto": _vm.goto
      }
    })], 1), step.mustAccept ? _c('div', {
      staticClass: "accept-wrap"
    }, [_c(VSpacer), _c(VBtn, {
      staticClass: "ma-2",
      attrs: {
        "color": "primary",
        "data-testid": "accept"
      },
      on: {
        "click": function click($event) {
          return _vm.nextStepAccept(step);
        }
      }
    }, [_vm._v("Akzeptieren")])], 1) : _vm._e()]);
  }), 1)], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };