import { RoseApi } from '../apiTypes';

export function createImageUploadApi(api: RoseApi) {
  return {
    upload: (
      image: string,
      { originalImage, source, purpose }: { originalImage?: string; source?: string; purpose?: string } = {},
    ) => api.metrics.post<{ id: string }>(`/imageUploads/upload`, { image, originalImage, source, purpose }),
    getImageDataUri: (imageId: string) => api.metrics.get<string>(`/imageUploads/image/${imageId}`),
    getImagePath: (imageId: string | number) => `/imageUploads/image/${imageId}`,
  };
}
