import { IHeuteResult } from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createHeuteApi(api: RoseApi) {
  return {
    getHeute: (tag: string, letztesUpdate: string) =>
      api.metrics.get<{ data: IHeuteResult }>(`/api/heute`, { query: { tag, letztesupodate: letztesUpdate } }),
    getAlarmCounts: ({ alarmkey, from, to, ids }: { alarmkey: string; from: string; to: string; ids: string[] }) =>
      api.metrics.get<{ tag: string; count: number }[]>(`/api/heute/alarm/${alarmkey}`, { query: { from, to, ids } }),
    getScore: ({ bereich, from, to, ids }: { bereich: string; from: string; to: string; ids: string[] }) =>
      api.metrics.get<{ tag: string; score: number }[]>(`/api/heute/score/${bereich}`, { query: { from, to, ids } }),
  };
}
