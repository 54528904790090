import { VApp } from 'vuetify/lib/components/VApp';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "app-wrap"
  }, [_vm.warning ? _c('div', {
    staticClass: "warning"
  }, [_c('div', [_vm._v(_vm._s(_vm.warning))])]) : _c('div', {
    staticClass: "notting"
  }), _c(VApp, {
    attrs: {
      "id": "inspire"
    }
  }, [_c('router-view')], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };