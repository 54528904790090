import { RoseApi } from '../apiTypes';
import { ITerminQualitaetKPIsResult, ITerminQualitaetResult, PrivatFilterTypeUI } from '../../../../types';
import * as qs from 'qs';

export function createTermineApi(api: RoseApi) {
  return {
    getTerminqualitaet: (
      von: string,
      bis: string,
      privat: PrivatFilterTypeUI,
      behandler?: string[],
      leistungserbringer?: string,
    ) =>
      api.metrics.get<ITerminQualitaetResult[]>(
        `/api/terminqualitaet?${qs.stringify({ von, bis, behandler, leistungserbringer, privat })}`,
      ),

    getTerminqualitaetKpis: (params: {
      von?: string;
      bis?: string;
      privat: PrivatFilterTypeUI;
      behandler?: string;
      leistungserbringer?: string;
    }) => api.metrics.get<ITerminQualitaetKPIsResult>(`/api/terminqualitaet/kpis?${qs.stringify(params)}`),
  };
}
