import { RoseApi } from '../apiTypes';
import { ISponsor, IGutschein } from '../../../../types';

export function createSponsoringApi(api: RoseApi) {
  return {
    // Sponsoren
    getSponsoren: () => api.metrics.get<ISponsor[]>(`/admin/sponsoren`),
    createSponsor: (sponsor: ISponsor, paymentMethod: string) =>
      api.metrics.put(`/admin/sponsoren`, { sponsor, paymentMethod }),
    updateSponsor: (sponsor: ISponsor) => api.metrics.post(`/admin/sponsoren`, { sponsor }),
    deleteSponsor: (sponsor: ISponsor) => api.metrics.delete(`/admin/sponsoren`, { sponsor }),
    // Gutscheine
    getGutscheine: () => api.metrics.get<IGutschein[]>(`/admin/gutscheine`),
    createGutschein: (gutschein: IGutschein) => api.metrics.put(`/admin/gutscheine`, { gutschein }),
    updateGutschein: (gutschein: IGutschein) => api.metrics.post(`/admin/gutscheine`, { gutschein }),
    deleteGutschein: (gutschein: IGutschein) => api.metrics.delete(`/admin/gutscheine`, { gutschein }),
    assignGutschein: (gutschein: IGutschein, subscriptionid: string) =>
      api.metrics.post(`/admin/addgutscheintosubscription`, { gutscheincode: gutschein.code, subscriptionid }),
  };
}
