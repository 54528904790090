import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VRow, {
    attrs: {
      "justify": "center"
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "10",
      "md": "8",
      "lg": "6"
    }
  }, [_c(VCard, {
    ref: "form"
  }, [_c(VCardText, [_c(VForm, {
    model: {
      value: _vm.formValid,
      callback: function callback($$v) {
        _vm.formValid = $$v;
      },
      expression: "formValid"
    }
  }, [_c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Admin-Benutzer"), _c(VBtn, {
    staticClass: "ml-2 pt-0",
    attrs: {
      "color": "info",
      "small": "",
      "data-testid": "takeAuftraggeberAdmin"
    },
    on: {
      "click": _vm.takeAuftraggeberAdmin
    }
  }, [_vm._v("Auftraggeber übernehmen")])], 1), _c('span', [_vm._v("Für diesen Benutzer wird der erste Account in rose angelegt. Er kann daraufhin weitere Benutzer für die Praxis anlegen.")]), _c('br'), _c('span', {
    staticClass: "bold-span"
  }, [_vm._v(" Falls Du schon einen rose Benutzer hast gib diesen bitte hier an.")]), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Name",
      "required": ""
    },
    model: {
      value: _vm.adminBenutzer.name,
      callback: function callback($$v) {
        _vm.$set(_vm.adminBenutzer, "name", $$v);
      },
      expression: "adminBenutzer.name"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Vorname",
      "required": ""
    },
    model: {
      value: _vm.adminBenutzer.vorname,
      callback: function callback($$v) {
        _vm.$set(_vm.adminBenutzer, "vorname", $$v);
      },
      expression: "adminBenutzer.vorname"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.email],
      "label": "E-Mail",
      "required": ""
    },
    model: {
      value: _vm.adminBenutzer.email,
      callback: function callback($$v) {
        _vm.$set(_vm.adminBenutzer, "email", $$v);
      },
      expression: "adminBenutzer.email"
    }
  }), _c('br'), _c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("rose Ansprechpartner"), _c(VBtn, {
    staticClass: "ml-2",
    attrs: {
      "color": "info",
      "small": "",
      "disabled": !(_vm.adminBenutzer.name && _vm.adminBenutzer.vorname && _vm.adminBenutzer.email),
      "data-testid": "takeAdminAnsprechpartner"
    },
    on: {
      "click": _vm.takeAdminAnsprechpartner
    }
  }, [_vm._v("Admin übernehmen")])], 1), _c('span', [_vm._v("Ein Ansprechpartner der bei fachlichen / technischen Fragen angesprochen werden kann.")]), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Name"
    },
    model: {
      value: _vm.technicalUser.name,
      callback: function callback($$v) {
        _vm.$set(_vm.technicalUser, "name", $$v);
      },
      expression: "technicalUser.name"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Vorname"
    },
    model: {
      value: _vm.technicalUser.vorname,
      callback: function callback($$v) {
        _vm.$set(_vm.technicalUser, "vorname", $$v);
      },
      expression: "technicalUser.vorname"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.email],
      "label": "E-Mail"
    },
    model: {
      value: _vm.technicalUser.email,
      callback: function callback($$v) {
        _vm.$set(_vm.technicalUser, "email", $$v);
      },
      expression: "technicalUser.email"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "tel",
      "label": "Telefon"
    },
    model: {
      value: _vm.technicalUser.tel,
      callback: function callback($$v) {
        _vm.$set(_vm.technicalUser, "tel", $$v);
      },
      expression: "technicalUser.tel"
    }
  })], 1)], 1), _c(VDivider, {
    staticClass: "mt-12"
  }), _c(VCardActions, [_c('div', {
    staticClass: "flex-grow-1"
  }), _c(VDialog, {
    attrs: {
      "width": "500"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_vm.needsToVerifyEmail ? _c(VBtn, _vm._g({
          attrs: {
            "color": "primary",
            "disabled": !_vm.formValid,
            "data-testid": "sendVerifyMail"
          },
          on: {
            "click": _vm.sendVerifyMail
          }
        }, on), [_vm._v("E-Mail verifizieren")]) : _c(VBtn, {
          attrs: {
            "color": "primary",
            "disabled": !_vm.formValid
          },
          on: {
            "click": _vm.sendVerifyMail
          }
        }, [_vm._v("Weiter")])];
      }
    }]),
    model: {
      value: _vm.emailVerifyDialog,
      callback: function callback($$v) {
        _vm.emailVerifyDialog = $$v;
      },
      expression: "emailVerifyDialog"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "headline primary color-white",
    attrs: {
      "primary-title": ""
    }
  }, [_vm._v("E-Mail verifizieren")]), _c(VCardText, [_c('div', {
    staticClass: "verify-info"
  }, [_c('p', [_c('span', [_vm._v("Du hast einen Verifikationscode an die EMail")]), _c('span', {
    staticClass: "email"
  }, [_vm._v(" " + _vm._s(_vm.adminBenutzer.email) + " ")]), _c('span', [_vm._v("erhalten.")])]), _c('p', [_c('span', [_vm._v("Bitte gib den Verifikationscode aus der EMail in das Feld ein, um fortzufahren.")])])]), _c(VTextField, {
    attrs: {
      "error": _vm.verificationInputError,
      "success": _vm.verificationInputSuccess,
      "label": "Verifikationscode",
      "outlined": "",
      "autofocus": "",
      "data-testid": "verificationCodeInput"
    },
    on: {
      "input": function input($event) {
        _vm.verificationInputError = false;
      },
      "blur": function blur($event) {
        _vm.verificationInputError = true;
      }
    },
    model: {
      value: _vm.verificationCodeInput,
      callback: function callback($$v) {
        _vm.verificationCodeInput = $$v;
      },
      expression: "verificationCodeInput"
    }
  })], 1), _c(VDivider), _c(VCardActions, [_c(VTooltip, {
    attrs: {
      "bottom": "",
      "open-on-click": "",
      "open-on-hover": false,
      "open-delay": 1000
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c(VBtn, _vm._g({
          attrs: {
            "color": "secondary",
            "text": "",
            "dark": ""
          },
          on: {
            "click": _vm.sendVerifyMail
          }
        }, on), [_vm._v("Erneut senden")])];
      }
    }])
  }, [_c('span', [_vm._v("Gesendet")])]), _c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "secondary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.emailVerifyDialog = false;
      }
    }
  }, [_vm._v("Daten korrigieren")])], 1)], 1)], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };