import { omit } from 'lodash';
import { RoseApi } from '../apiTypes';
import { IPatientDetailsQueryParams, IMetricsPatientDetails, IPatientStammbehandlerInfo } from '../../../../types';

export function createPatientApi(roseApi: RoseApi) {
  return {
    getPatientDetails: (params: IPatientDetailsQueryParams) =>
      roseApi.metrics.get<IMetricsPatientDetails>(`/api/patient/details/${params.patid}`, {
        query: omit(params, ['patid']),
      }),
    getPatienten: (patids: string[]) =>
      roseApi.metrics.post<IPatientStammbehandlerInfo[]>('/api/patient/patienten', { patids }),
  };
}
