import "core-js/modules/es.function.name.js";
import { defineComponent } from 'vue';
import { onboardingStore } from '@/state/onboardingStore';
import { sendConfirmationEmail } from '../services/data';
import { rules } from '../../../common-ui';
import { simpleHashCode } from '../../../base';
function verifyCodeFromEmail(mail) {
  return String(Math.abs(simpleHashCode(mail))).substr(0, 4);
}
export default defineComponent({
  components: {},
  data: function data() {
    return {
      isR4cOnboarding: onboardingStore.state.isR4cOnboarding,
      verificationInputError: false,
      verificationInputSuccess: false,
      verificationCodeInput: '',
      emailVerifyDialog: false,
      verifiedEmailAdress: '',
      adminBenutzer: {
        name: null,
        vorname: null,
        email: null
      },
      technicalUser: {
        name: null,
        vorname: null,
        email: null,
        tel: null
      },
      formValid: false,
      rules: rules
    };
  },
  computed: {
    calculatedVerifyCode: function calculatedVerifyCode() {
      if (!this.adminBenutzer || !this.adminBenutzer.email) {
        return '-';
      }
      return verifyCodeFromEmail(this.adminBenutzer.email);
    },
    needsToVerifyEmail: function needsToVerifyEmail() {
      if (!this.verifiedEmailAdress) {
        return true;
      }
      return this.verifiedEmailAdress !== this.adminBenutzer.email;
    },
    viewCompleteValid: function viewCompleteValid() {
      return this.formValid && !this.needsToVerifyEmail;
    }
  },
  watch: {
    verificationCodeInput: function verificationCodeInput(codeInput) {
      var _this = this;
      if (!this.adminBenutzer || !this.adminBenutzer.email) {
        return;
      }
      if (codeInput === this.calculatedVerifyCode || codeInput === 'messbar') {
        // code is correct
        this.verificationInputSuccess = true;
        this.verifiedEmailAdress = this.adminBenutzer.email;
        setTimeout(function () {
          _this.emailVerifyDialog = false;
          _this.next();
        }, 500);
      }
    },
    viewCompleteValid: function viewCompleteValid() {
      this.$emit('viewValidUpdate', this.viewCompleteValid);
    }
  },
  methods: {
    persistData: function persistData() {
      onboardingStore.commit.setAdminBenutzer(this.adminBenutzer);
      onboardingStore.commit.setTechnischerAnprechpartner(this.technicalUser);
    },
    next: function next() {
      this.$emit('next');
    },
    sendVerifyMail: function sendVerifyMail() {
      if (this.needsToVerifyEmail) {
        sendConfirmationEmail(this.adminBenutzer.email, this.calculatedVerifyCode);
      } else {
        this.next();
      }
    },
    takeAuftraggeberAdmin: function takeAuftraggeberAdmin(val) {
      if (onboardingStore.state.praxisDaten && onboardingStore.state.praxisDaten.auftraggeber) {
        var _onboardingStore$stat = onboardingStore.state.praxisDaten.auftraggeber,
          name = _onboardingStore$stat.name,
          vorname = _onboardingStore$stat.vorname,
          email = _onboardingStore$stat.email;
        this.adminBenutzer.name = name;
        this.adminBenutzer.vorname = vorname;
        this.adminBenutzer.email = email;
      }
    },
    takeAdminAnsprechpartner: function takeAdminAnsprechpartner(val) {
      this.technicalUser.name = this.adminBenutzer.name;
      this.technicalUser.vorname = this.adminBenutzer.vorname;
      this.technicalUser.email = this.adminBenutzer.email;
    }
  }
});