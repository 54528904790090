import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from 'vuetify/src/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: { de },
    current: 'de',
  },
  // icons: {
  //   iconfont: 'fa4',
  //   values: {
  //     cancel: 'fas fa-ban',
  //     menu: 'fas fa-ellipsis-v',
  //   },
  // },
  theme: {
    themes: {
      light: {
        primary: '#337ab7',
        secondary: '#347ab7',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#337ab7',
        success: '#4CAF50',
        warning: '#ec971f',
      },
    },
  },
});
