import { VCardText } from 'vuetify/lib/components/VCard';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "wrap"
  }, [_c(VCardText, {
    domProps: {
      "innerHTML": _vm._s(_vm.avvHtml)
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };