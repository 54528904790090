"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.token = void 0;
const lodash_1 = require("lodash");
/**
 * small convenience method to access token (parts) of a string
 *
 * @param s the given string to split
 * @param separator the separator the split is done with
 * @param t index of token to use, this is 1-based
 * @param fallback what will be returned if separator is not found
 * @example
 *      "la-li-lu".Token('-', 1) == "la"
 *      "la-li-lu".Token('-', 4) == fallback
 *      "la-li-lu".Token('-', 0) == "la-li-lu"
 *      "la-li-lu".Token('-', -1) == "lu"
 *      String.empty.Token('-', x) == fallback // for all x
 * @returns the token or the fallback
 */
function token(s, separator, t, fallback = '') {
    if (!(0, lodash_1.isEmpty)(s) && s.includes(separator)) {
        const tokens = (0, lodash_1.split)(s, separator);
        if (t > 0) {
            // means: start at the beginning
            const idx = t - 1;
            if (idx < tokens.length) {
                return tokens[idx];
            }
        }
        else if (t < 0) {
            //  mean: start from end
            const idx = tokens.length + t;
            if (idx >= 0) {
                return tokens[idx];
            }
        }
        else {
            return s;
        }
    }
    return fallback;
}
exports.token = token;
