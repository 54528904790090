import { RoseApi } from '../apiTypes';
import { ICharlyAnalyticsLicenceInfo } from '../../../../types';

export function createCALicenceApi(api: RoseApi) {
  return {
    getLicences: () => api.metrics.get<ICharlyAnalyticsLicenceInfo[]>(`/admin/calicence`),
    createLicence: (licence: ICharlyAnalyticsLicenceInfo) => api.metrics.put(`/admin/calicence`, { licence }),
    updateLicence: (licence: ICharlyAnalyticsLicenceInfo) => api.metrics.post(`/admin/calicence`, { licence }),
    deleteLicence: (licence: ICharlyAnalyticsLicenceInfo) =>
      api.metrics.delete<number>(`/admin/calicence/${licence.serialnumber}/${licence.tenant}`),
  };
}
