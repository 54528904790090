"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.benchmarkConfigPatienten = void 0;
const types_1 = require("../../../types");
function createAltersPyramide() {
    const ap = {};
    for (let i = 0; i < 10; i++) {
        ap[`_alter${i}`] = {
            name: `${i * 10}-${(i + 1) * 10 - 1} J`,
            unit: types_1.BenchmarkConfigUnitType.PATIENTEN,
            trendType: types_1.IBenchmarkConfigTrendType.biggerisbetter,
            virtual: true,
            smartValue: 's / b.patienten * 100',
            smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
            value: `b.altersPyramide['${i}']`,
            debug: [`altersPyramide['${i}patids']`],
            canFilterByPatid: true,
        };
    }
    ap._alter100 = {
        name: `>= 100 J`,
        unit: types_1.BenchmarkConfigUnitType.PATIENTEN,
        trendType: types_1.IBenchmarkConfigTrendType.biggerisbetter,
        virtual: true,
        smartValue: 's / b.patienten * 100',
        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
        value: `b.altersPyramide['100']`,
        debug: [`altersPyramide['100patids']`],
        canFilterByPatid: true,
    };
    return ap;
}
function benchmarkConfigPatienten() {
    return {
        name: 'Behandelte Patienten',
        isHeader: true,
        value: 'b.patienten',
        virtual: true,
        smartValue: '100',
        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
        help: 'Als **behandelter Patienten** gilt jeder Patient, an dem im ausgewählten Zeitraum Honorarleistungen erbracht wurden.',
        childs: {
            patienten: {
                name: 'Insgesamt',
                unit: types_1.BenchmarkConfigUnitType.PATIENTEN,
                smartValue: '100',
                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                help: 'Als **behandelter Patienten** gilt jeder Patient, an dem im ausgewählten Zeitraum Honorarleistungen erbracht wurden.',
                collapsed: true,
                debug: ['patientenIds'],
                canFilterByPatid: true,
                childs: {
                    patientenAktiv: {
                        name: 'Aktiv',
                        unit: types_1.BenchmarkConfigUnitType.PATIENTEN,
                        smartValue: 's / b.patienten * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        help: 'Als **aktiver** behandelter Patienten gilt jeder Patient, an dem im ausgewählten Zeitraum Honorarleistungen erbracht wurden und der **nicht** veraltet wurde.',
                        debug: ['patientenAktivIds'],
                        canFilterByPatid: true,
                    },
                    patientenVeraltet: {
                        name: 'Inaktiv (veraltet)',
                        unit: types_1.BenchmarkConfigUnitType.PATIENTEN,
                        smartValue: 's / b.patienten * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        help: 'Als **inaktiver** behandelter Patienten gilt jeder Patient, an dem im ausgewählten Zeitraum Honorarleistungen erbracht wurden und der veraltet wurde.',
                        debug: ['patientenVeraltetIds'],
                        canFilterByPatid: true,
                    },
                    patientenKeinNotfall: {
                        name: 'Nicht Notfall-Patienten',
                        unit: types_1.BenchmarkConfigUnitType.PATIENTEN,
                        smartValue: 's / b.patienten * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        help: 'Ein Patient, in dessen erstem Termin kein **Notdienst-Zuschlag** abgerechnet wurde, wird als **Nicht-Notfall-Patient** eingestuft. Dies sind in der Regel die meisten Patienten. Die Einteilung ist für die Unterscheidung von "Notfall-" und "Nicht-Notfall-Patienten" wichtig.',
                        debug: ['patientenKeinNotfallIds'],
                        canFilterByPatid: true,
                    },
                    patientenNotfall: {
                        name: 'Notfall-Patienten',
                        unit: types_1.BenchmarkConfigUnitType.PATIENTEN,
                        smartValue: 's / b.patienten * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        help: 'Ein Patient, in dessen erstem Termin ein **Notdienst-Zuschlag** abgerechnet wurde, wird als **Notfall-Patient** eingestuft. Er gilt damit dauerhaft als **Notfall-Patient**, der im Notdienst zur Praxis gefunden hat. Es spielt somit keine Rolle ob der Notdienst im Betrachtungszeitraum oder zuvor stattgefunden hat.',
                        debug: ['patientenNotfallIds'],
                        canFilterByPatid: true,
                    },
                },
            },
            patientenPrivat: {
                name: 'Privat',
                unit: types_1.BenchmarkConfigUnitType.PATIENTEN,
                smartValue: 's / b.patienten * 100',
                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                help: 'Patienten mit der Abrechnungsart privat und Selbstzahler',
                debug: ['patientenPrivatIds'],
                canFilterByPatid: true,
            },
            patientenKasse: {
                name: 'Kasse',
                unit: types_1.BenchmarkConfigUnitType.PATIENTEN,
                smartValue: 's / b.patienten * 100',
                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                help: 'Patienten mit der Abrechnungsart Kasse',
                debug: ['patientenKasseIds'],
                canFilterByPatid: true,
            },
            patientenNeu: {
                name: 'Neupatienten',
                unit: types_1.BenchmarkConfigUnitType.PATIENTEN,
                smartValue: 's / b.patienten * 100',
                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                help: 'Ein Patient, der im ausgewählten Zeitraum erstmalig einen Termin hatte, an dem Leistungen erbracht wurden, wird als **Neupatient** gezählt.',
                debug: ['patientenNeuIds'],
                canFilterByPatid: true,
            },
            patientenFrau: {
                name: 'Frauen',
                unit: types_1.BenchmarkConfigUnitType.PATIENTEN,
                smartValue: 's / b.patienten * 100',
                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                help: 'Es werden nur Mann/Frau gezählt. Neutral bleibt unberücksichtigt.',
                debug: ['patientenFrauIds'],
                canFilterByPatid: true,
            },
            patientenMann: {
                name: 'Männer',
                unit: types_1.BenchmarkConfigUnitType.PATIENTEN,
                smartValue: 's / b.patienten * 100',
                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                help: 'Es werden nur Mann/Frau gezählt. Neutral bleibt unberücksichtigt.',
                debug: ['patientenMannIds'],
                canFilterByPatid: true,
            },
            patientenErwachsen: {
                name: 'Erwachsene >= 18J',
                unit: types_1.BenchmarkConfigUnitType.PATIENTEN,
                smartValue: 's / b.patienten * 100',
                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                debug: ['patientenErwachsenIds'],
                canFilterByPatid: true,
            },
            _patientenKinderUndJugendliche: {
                name: 'Kinder und Jugendliche',
                unit: types_1.BenchmarkConfigUnitType.PATIENTEN,
                virtual: true,
                value: '+b.patientenJugendlich + +b.patientenKind',
                smartValue: 's / b.patienten * 100',
                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                debug: ['patientenJugendlichIds', 'patientenKindIds'],
                canFilterByPatid: true,
                childs: {
                    patientenJugendlich: {
                        name: '6-17J',
                        smartValue: 's / b.patienten * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        unit: types_1.BenchmarkConfigUnitType.PATIENTEN,
                        debug: ['patientenJugendlichIds'],
                        canFilterByPatid: true,
                    },
                    patientenKind: {
                        name: '< 6J',
                        unit: types_1.BenchmarkConfigUnitType.PATIENTEN,
                        smartValue: 's / b.patienten * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        debug: ['patientenKindIds'],
                        canFilterByPatid: true,
                    },
                },
            },
            alter: {
                name: '⌀ Alter',
                unit: types_1.BenchmarkConfigUnitType.YEARS,
                trendType: types_1.IBenchmarkConfigTrendType.nonebutabsolut,
                noAvg: true,
                collapsed: true,
                childs: createAltersPyramide(),
            },
        },
    };
}
exports.benchmarkConfigPatienten = benchmarkConfigPatienten;
