import { ILabelConfig, IActionResult, IAbrechnungsVorschlagLabels } from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createAbrechnungslabelsApi(api: RoseApi) {
  return {
    saveAbrechnungslabels: (patid: string, tag: Date, regel: string, selectedLabels: ILabelConfig[]) =>
      api.metrics.post<IActionResult<void>>(`/api/abrechnungslabels/saveabrechnungslabels`, {
        patid,
        tag,
        regel,
        selectedLabels,
      }),
    saveLabelConfig: (labelConfig: ILabelConfig[]) =>
      api.metrics.post<IActionResult<void>>(`/api/abrechnungslabels/savelabelconfig`, { labelConfig }),
    getAbrechnungslabelsForPatient: (patid: string) =>
      api.metrics.get<IAbrechnungsVorschlagLabels[]>(`/api/abrechnungslabels/getAbrechnungsLabelsForPat/${patid}`),
  };
}
