import { RoseApi } from '../apiTypes';
import { FEATURES, IProfile, rzkeys } from '../../../../types';

export function createR4cAppsApi(api: RoseApi) {
  return {
    editR4cApps: (payload: { addFeatures: FEATURES[]; removeFeatures: FEATURES[] }) =>
      api.r4cDispatch.post<any>(`/r4capps/r4c-edit-apps`, payload),
    editRechenzentrum: (
      r4chost: string,
      payload: { rechenzentrenData: Record<rzkeys, any>; rzIdsToRemove: number[] },
    ) => api.r4c.post<any>(r4chost, `/r4capps/r4c-edit-rz`, payload),
    loadClientProfile: (cid: string) => api.r4cDispatch.get<IProfile>(`/r4capps/profile`, { clientIdHeader: cid }),
  };
}
