import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.viewNeedsEdit ? _c(VBtn, {
    attrs: {
      "depressed": "",
      "color": "error"
    },
    on: {
      "click": _vm.jumpToView
    }
  }, [_c(VIcon, {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-alert")]), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Ändern")])], 1) : _c(VBtn, {
    attrs: {
      "depressed": ""
    },
    on: {
      "click": _vm.jumpToView
    }
  }, [_c(VIcon, {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-pencil")]), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Ändern")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };