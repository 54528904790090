import { IOnboardingDaten, FEATURES } from '../../../types';
import { r4cServerApi, sendOnboardingRequest, sendR4cOnboardingRequest } from '@/services/data';
import { createR4cPayload, onboardingStore } from '@/state/onboardingStore';
// Step Components
import MetricsPaketVue from '../components/MetricsPaket.vue';
import PraxisdatenVue from '../components/Praxisdaten.vue';
import AdminVue from '../components/Admin.vue';
import ZahlungsdatenVue from '../components/Zahlungsdaten.vue';
import AGBVue from '../components/AGB.vue';
import R4cAGBVue from '../components/R4cAGB.vue';
import AVVVue from '../components/AVV.vue';
import CheckVue from '../components/Check.vue';
import FertigVue from '../components/Fertig.vue';
import R4cPaketVue from '../components/R4cPaket.vue';
import DatenschutzbestimmungVue from '../components/Datenschutzbestimmung.vue';
import RechenzentrenVue from '../components/Rechenzentren.vue';
import RoseAccountVue from '../components/RoseAccount.vue';

export enum StepId {
  unknown = 0,

  paket = 1,
  praxisdaten = 2,
  admin = 3,
  zahlungsdaten = 4,
  agb = 5,
  avv = 6,
  check = 7,
  fertig = 8,

  r4cPaketAuswahl = 11,
  r4cRechenzentren = 13,
  r4cDatenschutz = 14,
  r4cAgb = 15,

  account = 16,
}

export interface IStepViewConfig {
  name: string;
  stepId: StepId;
  component: any;
  mustAccept?: boolean;
}

export const stepToComponent: IStepViewConfig[] = [
  { name: 'Paket', component: MetricsPaketVue, stepId: StepId.paket },
  { name: 'Praxisdaten', component: PraxisdatenVue, stepId: StepId.praxisdaten },
  { name: 'Admin', component: AdminVue, stepId: StepId.admin },
  { name: 'Zahlung', component: ZahlungsdatenVue, stepId: StepId.zahlungsdaten },
  { name: 'AGB', component: AGBVue, mustAccept: true, stepId: StepId.agb },
  { name: 'AVV', component: AVVVue, mustAccept: true, stepId: StepId.avv },
  { name: 'Prüfen', component: CheckVue, stepId: StepId.check },
  { name: 'Fertig', component: FertigVue, stepId: StepId.fertig },

  // r4c specific
  { name: 'Apps', component: R4cPaketVue, stepId: StepId.r4cPaketAuswahl },
  { name: 'Rechenzentrum', component: RechenzentrenVue, stepId: StepId.r4cRechenzentren },
  { name: 'rose-Account', component: RoseAccountVue, stepId: StepId.account },
  { name: 'AGB', component: R4cAGBVue, mustAccept: true, stepId: StepId.r4cAgb },
  {
    name: 'Datenschutz',
    component: DatenschutzbestimmungVue,
    mustAccept: true,
    stepId: StepId.r4cDatenschutz,
  },
];

export function getStepViewConfig(stepId: StepId) {
  const stepViewCofig = stepToComponent.find(entry => entry.stepId === stepId);
  if (!stepViewCofig) {
    throw new Error(`no stepViewCofig for ${stepId}`);
  }
  return stepViewCofig;
}

// STEP FLOWS
export interface FlowConfigEntry {
  stepId: StepId;
  enableWhen?: any;
  viewParams?: any;
}
export type FlowConfig = FlowConfigEntry[];

export interface OnboardingAppTask {
  stepFlow: FlowConfig;
  finalApiCall: (state: typeof onboardingStore.state) => Promise<any>;
  isR4cOnboarding?: boolean; // maybe remove this later
}

export const OnboardingMetricsTask: OnboardingAppTask = {
  stepFlow: [
    { stepId: StepId.paket },
    { stepId: StepId.praxisdaten },
    { stepId: StepId.admin },
    { stepId: StepId.zahlungsdaten },

    { stepId: StepId.agb },
    { stepId: StepId.avv },

    { stepId: StepId.check },
    { stepId: StepId.fertig },
  ],
  finalApiCall: async (state: typeof onboardingStore.state) => {
    const payload: IOnboardingDaten = {
      lizenzInfo: state.lizenzInfo,
      praxisDaten: state.praxisDaten,
      adminBenutzer: state.adminBenutzer,
      ansprechpartner: state.technischerAnprechpartner,
      zahlungsdaten: state.zahlungsdaten,
      kommentar: state.kommentar,
    };

    await sendOnboardingRequest(payload);
  },
};

export const OnboardingR4cTask: OnboardingAppTask = {
  isR4cOnboarding: true,
  stepFlow: [
    { stepId: StepId.r4cPaketAuswahl },
    { stepId: StepId.r4cRechenzentren },
    { stepId: StepId.account },
    { stepId: StepId.praxisdaten },
    { stepId: StepId.admin },
    { stepId: StepId.zahlungsdaten },

    { stepId: StepId.r4cAgb },
    { stepId: StepId.avv },
    { stepId: StepId.r4cDatenschutz },

    { stepId: StepId.check },
    { stepId: StepId.fertig },
  ],
  finalApiCall: async (state: typeof onboardingStore.state) => {
    const payload = createR4cPayload(state);
    await sendR4cOnboardingRequest(payload);
  },
};

export const EditR4cAppsTask: OnboardingAppTask = {
  stepFlow: [
    { stepId: StepId.r4cPaketAuswahl, viewParams: { editR4cApps: true } },
    { stepId: StepId.r4cRechenzentren },
    { stepId: StepId.check },
    { stepId: StepId.fertig },
  ],
  finalApiCall: async (state: typeof onboardingStore.state) => {
    console.log('state', state);
    const addFeatures: FEATURES[] = [];
    const removeFeatures: FEATURES[] = [];

    const activeFeatures = state.roseProfile?.lizenzen;
    if (!activeFeatures) {
      throw new Error('edit r4c apps without activeFeatuers in profile');
    }

    // apply factoring licence diff
    const featureDiff = (activeFeature: () => FEATURES | undefined, choosenFeature: () => FEATURES | null) => {
      const af = activeFeature();
      const cf = choosenFeature();
      if (af !== cf) {
        if (af && af !== FEATURES.NONE) {
          // remove current feature
          removeFeatures.push(af);
        }
        if (cf) {
          // add new feature
          addFeatures.push(cf);
        }
      }
    };

    featureDiff(
      () => onboardingStore.getters.activeFactoringFeature,
      () => state.factoringFeature,
    );
    featureDiff(
      () => onboardingStore.getters.activeDocumentsFeature,
      () => state.documentsFeature,
    );
    featureDiff(
      () => onboardingStore.getters.activeAnamneseFeature,
      () => state.anamneseFeature,
    );

    const payload = {
      addFeatures,
      removeFeatures,
      rechenzentrenData: state.rechenzentrenData,
    };
    console.log(`editR4cApps payload`, payload);
    await r4cServerApi.r4cApps.editR4cApps(payload);
  },
};

export const EditR4cRzTask: OnboardingAppTask = {
  stepFlow: [
    { stepId: StepId.r4cRechenzentren, viewParams: { isAdditionalRz: true, allowRzRemoval: true } },

    { stepId: StepId.check },
    { stepId: StepId.fertig },
  ],
  finalApiCall: async (state: typeof onboardingStore.state) => {
    const payload = {
      rechenzentrenData: state.rechenzentrenData,
      rzIdsToRemove: state.rechenzentrenToDelete.map(rz => rz.id || 0),
      secret: state.r4cSecret,
    };

    await r4cServerApi.r4cApps.editRechenzentrum(state.roseProfile!.r4chost, payload);
  },
};

export type PaketId = 'White' | 'Red' | 'Black' | 'Diamond';
export type IStep = IStepViewConfig & FlowConfigEntry & { complete: boolean; skipped: boolean };
