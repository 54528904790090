import {
  ICollectionStatistics,
  ICollectionStatsQueryParams,
  IApiKey,
  IRequestResult,
  IImportProtocolComplete,
} from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createMainApi(api: RoseApi) {
  return {
    collectionStats: (params: ICollectionStatsQueryParams) =>
      api.metrics.get<ICollectionStatistics[]>(`/api/collectionstats`, {
        query: {
          ...params,
          from: params.from?.toISOString(),
          to: params.to?.toISOString(),
        },
      }),
    importProtocol: (id: string) => api.metrics.get<IImportProtocolComplete>(`/api/importprotocol/${id}`),
    getApiKey: () => api.metrics.get<IRequestResult<IApiKey>>(`/api/apikey`),
    getViews: (all: string) => api.metrics.get<{ views: string[] }>(`/api/views`, { query: { all } }),
  };
}
